import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

import { VEHICLETYPECODE } from '../enums/vehicleTypeCode';
import { TASK_TYPE_ENUM } from '../enums/taskTypeEnum';
import { TASK_STATUS_ENUM } from '../enums/taskStatus';

// Actions
export const LOAD_TASK_LIST_SUCCESS = 'LOAD_TASK_LIST_SUCCESS';
export const LOAD_TASK_LIST_FAILED = 'LOAD_TASK_LIST_FAILED';
export const LOAD_TASK_LIST_IN_PROGRESS = 'LOAD_TASK_LIST_IN_PROGRESS';

export const LOAD_TASK_SUCCESS = 'LOAD_TASK_SUCCESS';
export const LOAD_TASK_FAILED = 'LOAD_TASK_FAILED';

export const LOAD_PREDEFINED_TASK_NAME_LIST_SUCCESS = 'LOAD_PREDEFINED_TASK_NAME_LIST_SUCCESS';
export const LOAD_PREDEFINED_TASK_NAME_LIST_FAILED = 'LOAD_PREDEFINED_TASK_NAME_LIST_FAILED';

export const NEW_TASK = 'NEW_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILED = 'CREATE_TASK_FAILED';

export const EDIT_TASK = 'EDIT_TASK';
export const SAVE_TASK_SUCCESS = 'SAVE_TASK_SUCCESS';
export const SAVE_TASK_FAILED = 'SAVE_TASK_FAILED';

export const UPDATE_TASK_PROPERTY = 'UPDATE_TASK_PROPERTY';
export const CLOSE_TASK_FORM = 'CLOSE_TASK_FORM';

export const CLEAR_SELECTED_TASK = 'CLEAR_SELECTED_TASK';

export const CLOSE_TASK_SUCCESS = 'CLOSE_TASK_SUCCESS';
export const CLOSE_TASK_FAILED = 'CLOSE_TASK_FAILED';

export const CLONE_TASK = 'CLONE_TASK';
export const CLONE_TASK_SUCCESS = 'CLONE_TASK_SUCCESS';
export const CLONE_TASK_FAILED = 'CLONE_TASK_FAILED';

export const LOAD_TASK_CONFIG_SUCCESS = 'LOAD_TASK_CONFIG_SUCCESS';
export const LOAD_TASK_CONFIG_FAILED = 'LOAD_TASK_CONFIG_FAILED';

const ERROR = {
  NAME_REQUIRED: 'ERROR_NAME_REQUIRED',
  CLIENT_REQUIRED: 'ERROR_CLIENT_REQUIRED',
  SITE_REQUIRED: 'ERROR_SITE_REQUIRED',
  PLOT_REQUIRED: 'ERROR_PLOT_REQUIRED',
  ROUTE_REQUIRED: 'ERROR_ROUTE_REQUIRED',
  PILOT_REQUIRED: 'ERROR_PILOT_REQUIRED',
  ASSIGNED_USER_REQUIRED: 'ERROR_ASSIGNED_USER_REQUIRED',
  DRONE_REQUIRED: 'ERROR_DRONE_REQUIRED',
  VEHICLE_REQUIRED: 'VEHICLE_REQUIRED',
};

const initialState = {
  all: [],
  selected: undefined,
  configuration: undefined,
  isLoading: false,
  predefinedNames: [],
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TASK_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
        isLoading: false,
      };

    case LOAD_TASK_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case LOAD_TASK_LIST_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_TASK_SUCCESS:
      return {
        ...state,
        selected: action.data,
      };

    case LOAD_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_PREDEFINED_TASK_NAME_LIST_SUCCESS:
      return {
        ...state,
        predefinedNames: action.data,
      };
    case LOAD_PREDEFINED_TASK_NAME_LIST_FAILED:
      return {
        ...state,
      };
    case NEW_TASK:
      return {
        ...state,
        form: {
          ...action.data,
          isNew: true,
        },
      };

    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: {
          ...state.form,
          ...action.data,
        },
      };

    case CREATE_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case EDIT_TASK:
      return {
        ...state,
        form: {
          ...state.selected,
        },
      };

    case SAVE_TASK_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        form: undefined,
      };

    case SAVE_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case UPDATE_TASK_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };

    case CLOSE_TASK_FORM:
      return {
        ...state,
        form: undefined,
      };

    case CLEAR_SELECTED_TASK:
      return {
        ...state,
        selected: undefined,
      };

    case CLOSE_TASK_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
      };

    case CLOSE_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CLONE_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_TASK_CONFIG_SUCCESS:
      return {
        ...state,
        configurations: action.data,
      };

    case LOAD_TASK_CONFIG_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

// Validator
const validateTask = ({
  name,
  clientId,
  siteId,
  plotIds,
  routeId,
  vehicleTypeCode,
  parameters: { droneModelId, vehicleModelId, taskType },
}) => {
  if (!name || name.trim() === '') {
    return ERROR.NAME_REQUIRED;
  }
  if (!clientId || clientId === '') {
    return ERROR.CLIENT_REQUIRED;
  }
  if (!siteId || siteId === '') {
    return ERROR.SITE_REQUIRED;
  }

  if (taskType === TASK_TYPE_ENUM.CorridorMapping) {
    if (!routeId) {
      return ERROR.ROUTE_REQUIRED;
    }
  } else {
    if (!plotIds || plotIds.length === 0) {
      return ERROR.PLOT_REQUIRED;
    }
  }

  if (vehicleTypeCode === VEHICLETYPECODE.DRONE && !droneModelId) {
    return ERROR.DRONE_REQUIRED;
  }

  if (vehicleTypeCode === VEHICLETYPECODE.TRACTOR && !vehicleModelId) {
    return ERROR.VEHICLE_REQUIRED;
  }

  return;
};

const validateCloseTask = ({
  name,
  clientId,
  siteId,
  plotIds,
  routeId,
  assignedUserId,
  vehicleTypeCode,
  parameters: { taskType, droneModelId, vehicleModelId },
}) => {
  const error = validateTask({
    name,
    clientId,
    siteId,
    plotIds,
    routeId,
    vehicleTypeCode,
    parameters: { taskType, droneModelId, vehicleModelId },
  });

  if (error) {
    return error;
  }

  if (!assignedUserId || assignedUserId === '') {
    return ERROR.ASSIGNED_USER_REQUIRED;
  }
  return;
};

const getErrorMessage = (error) => {
  switch (error) {
    case ERROR.NAME_REQUIRED:
      return i18next.t('task:control.notification.create_failed_name_required_message.description');

    case ERROR.CLIENT_REQUIRED:
      return i18next.t('task:control.notification.create_failed_client_required_message.description');

    case ERROR.SITE_REQUIRED:
      return i18next.t('task:control.notification.create_failed_site_required_message.description');

    case ERROR.PLOT_REQUIRED:
      return i18next.t('task:control.notification.create_failed_plot_required_message.description');

    case ERROR.DRONE_REQUIRED:
      return i18next.t('task:control.notification.create_failed_drone_required_message.description');

    case ERROR.VEHICLE_REQUIRED:
      return i18next.t('task:control.notification.create_failed_vehicle_required_message.description');

    case ERROR.ASSIGNED_USER_REQUIRED:
      return i18next.t('task:control.notification.close_failed_assigned_user_required_message.description');

    default:
      return i18next.t('task:control.notification.create_failed_message.description', 'There is something wrong, please try again.');
  }
};

// Action Creators
export const loadTaskListSuccess = (data) => {
  return {
    type: LOAD_TASK_LIST_SUCCESS,
    data,
  };
};

export const loadTaskListFailed = (error) => {
  return {
    type: LOAD_TASK_LIST_FAILED,
    error,
  };
};

export const loadTaskListInProgress = () => {
  return {
    type: LOAD_TASK_LIST_IN_PROGRESS,
  };
};

export const loadTaskList = () => {
  return (dispatch) => {
    dispatch(loadTaskListInProgress());
    const statuses = Object.values(TASK_STATUS_ENUM);
    Promise.all(
      statuses.map((c) =>
        axios.get(`/tasks?status=${c}&includeFields=flights,site&$sort[updatedAt]=-1&$sort[dueDate]=-1`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          params: {
            '[parameters.taskType][$in]': ['Spraying', 'Mapping', 'CorridorMapping'],
          },
        })
      )
    )
      .then((response) => {
        dispatch(loadTaskListSuccess([].concat(...response.map((res) => res.data || []))));
      })
      .catch((error) => dispatch(loadTaskListFailed(error)));
  };
};

export const loadTaskSuccess = (data) => {
  return {
    type: LOAD_TASK_SUCCESS,
    data,
  };
};

export const loadTaskFailed = (error) => {
  return {
    type: LOAD_TASK_FAILED,
    error,
  };
};

export const loadTask = (id) => {
  return (dispatch) => {
    axios
      .get(`/tasks/${id}?includeFields=flights,client,site,assignedUser,plots,routeInfo,plantInfoList`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        const data = {
          ...response.data,
          vehicleTypeCode: response.data.parameters.droneModelId ? VEHICLETYPECODE.DRONE : VEHICLETYPECODE.TRACTOR,
        };
        dispatch(loadTaskSuccess(data));
      })
      .catch((error) => {
        dispatch(loadTaskFailed(error));
      });
  };
};

export const loadPredefinedTaskNameListSuccess = (data) => {
  return {
    type: LOAD_PREDEFINED_TASK_NAME_LIST_SUCCESS,
    data,
  };
};

export const loadPredefinedTaskNameListFailed = (data) => {
  return {
    type: LOAD_PREDEFINED_TASK_NAME_LIST_FAILED,
    data,
  };
};

export const loadPredefinedNameList = () => {
  return (dispatch) => {
    axios
      .get(`/predefined-task-names`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadPredefinedTaskNameListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPredefinedTaskNameListFailed(error.response));
      });
  };
};

export const newTask = (data) => {
  if (!data || !data.vehicleTypeCode) {
    data = { ...data, vehicleTypeCode: VEHICLETYPECODE.DRONE };
  }

  return {
    type: NEW_TASK,
    data,
  };
};

export const closeTask = (task, onSuccess) => {
  return (dispatch) => {
    const error = validateCloseTask(task);
    if (error) {
      dispatch(closeTaskFailed(error));
      return;
    }

    axios
      .post(`/tasks/${task._id}/close`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(closeTaskSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        dispatch(closeTaskFailed(error));
      });
  };
};

export const closeTaskSuccess = (data) => {
  notification['success']({
    message: i18next.t('task:control.notification.close_task_success_message.message', 'Successful!'),
    description: i18next.t('task:control.notification.close_task_success_message.description', 'New task has been created.'),
    className: 'sub2',
  });
  return {
    type: CLOSE_TASK_SUCCESS,
    data,
  };
};

export const closeTaskFailed = (error) => {
  const message = getErrorMessage(error);
  notification['error']({
    message: i18next.t('task:control.notification.close_task_failed_message.message', 'Error!'),
    description: message,
    className: 'sub2',
  });
  return {
    type: CLOSE_TASK_FAILED,
    error,
  };
};

export const createTask = (data) => {
  return (dispatch) => {
    axios
      .post('/tasks', data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createTaskSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createTaskFailed(error));
      });
  };
};

export const createTaskSuccess = (data) => {
  notification['success']({
    message: i18next.t('task:control.notification.create_success_message.message', 'Successful!'),
    description: i18next.t('task:control.notification.create_success_message.description', 'New task has been created.'),
    className: 'sub2',
  });
  return {
    type: CREATE_TASK_SUCCESS,
    data,
  };
};

export const createTaskFailed = (error) => {
  const message = getErrorMessage(error);
  notification['error']({
    message: i18next.t('task:control.notification.create_failed_message.message', 'Error!'),
    description: message,
    className: 'sub2',
  });
  return {
    type: CREATE_TASK_FAILED,
    error,
  };
};

export const saveTask = (id, data) => {
  return (dispatch) => {
    axios
      .patch(`/tasks/${id}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveTaskSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveTaskFailed(error));
      });
  };
};

export const saveTaskSuccess = (data) => {
  notification['success']({
    message: i18next.t('task:control.notification.save_success_message.message', 'Successful!'),
    description: i18next.t('task:control.notification.save_success_message.description', 'The task has been edited.'),
    className: 'sub2',
  });
  return {
    type: SAVE_TASK_SUCCESS,
    data,
  };
};

export const saveTaskFailed = (error) => {
  const message = getErrorMessage(error);
  notification['error']({
    message: i18next.t('task:control.notification.create_failed_message.message', 'Error!'),
    description: message,
    className: 'sub2',
  });
  return {
    type: SAVE_TASK_FAILED,
    error,
  };
};

export const updateTaskProperty = (data) => {
  return {
    type: UPDATE_TASK_PROPERTY,
    data,
  };
};

export const editTask = () => {
  return {
    type: EDIT_TASK,
  };
};

export const cloneTask = (id, onSuccess) => {
  return (dispatch) => {
    axios
      .post(
        `/tasks/${id}/clone`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        }
      )
      .then((response) => {
        onSuccess();
        dispatch(cloneTaskSuccess());
      })
      .catch((error) => {
        dispatch(cloneTaskFailed(error));
      });
  };
};

export const cloneTaskSuccess = () => {
  return (dispatch) => {
    dispatch(loadTaskList());
  };
};

export const cloneTaskFailed = (error) => {
  const message = getErrorMessage(error);
  notification['error']({
    message: i18next.t('task:control.notification.clone_task_failed_message.message', 'Error!'),
    description: message,
    className: 'sub2',
  });
  return {
    type: CLONE_TASK_FAILED,
    error,
  };
};

export const closeTaskForm = () => {
  return {
    type: CLOSE_TASK_FORM,
  };
};

export const clearSelectedTask = () => {
  return {
    type: CLEAR_SELECTED_TASK,
  };
};

export const loadTaskTypeConfigurationSuccess = (data) => {
  return {
    type: LOAD_TASK_CONFIG_SUCCESS,
    data,
  };
};

export const loadTaskTypeConfigurationFailed = (error) => {
  return {
    type: LOAD_TASK_CONFIG_FAILED,
    error,
  };
};

export const loadTaskTypeConfiguration = () => {
  return (dispatch) => {
    axios
      .get(`/task-type-configurations`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadTaskTypeConfigurationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadTaskTypeConfigurationFailed(error));
      });
  };
};
