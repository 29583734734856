import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { HEADERSTATUS } from '../../../../enums/datepickerHeaderStatus';

const BoardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  padding: 8px 12px;
`;

const BoardCard = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => props.index === props.indexMonthValue && props.yearValue === props.yearRunning && props.theme.gray_100};
  background-color: ${(props) =>
    props.index === props.indexMonthSelected && props.monthYearSelected === props.pendingMonthYear && props.theme.secondary_400};
  color: ${(props) => props.index === props.indexMonthSelected && props.monthYearSelected === props.pendingMonthYear && props.theme.light};
  &:hover {
    background-color: ${(props) => props.theme.gray};
  }
`;

const MonthPicker = (props) => {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthYearValue = dayjs(props.value);
  const monthYearSelected = dayjs(props.monthYearSelected);
  const pendingMonthYear = dayjs(props.pendingMonthYear);

  return (
      <BoardBody>
        {month.map((item, index) => (
          <BoardCard
            id={(`month-${props.id}-${item}`).toLowerCase()}
            onClick={() => props.onPendingMonthYearValueChanged(dayjs(`${item} ${pendingMonthYear.format('YYYY')}`), HEADERSTATUS.MONTH)}
            index={index}
            indexMonthValue={month.indexOf(monthYearValue.format('MMM'))}
            yearValue={monthYearValue.format('YYYY')}
            indexMonthSelected={month.indexOf(monthYearSelected.format('MMM'))}
            monthYearSelected={monthYearSelected.format('MMM YYYY')}
            pendingMonthYear={pendingMonthYear.format('MMM YYYY')}
            yearRunning={pendingMonthYear.format('YYYY')}>
            {item}
          </BoardCard>
        ))}
      </BoardBody>
  );
};

export default MonthPicker;
