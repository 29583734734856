import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: grid;
  position: relative;
  grid-gap: 16px;
  grid-template-rows: auto 1fr;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: ${(props) => (props.children && props.children.length > 2 ? '1px' : '0px')};
    background: ${(props) => props.theme.border_line};
    bottom: -1px;
  }

  width: 100%;
`;

const Title = styled.div.attrs({ className: 'headline6' })`
  color: ${(props) => props.theme.primary};
  padding-left: 8px;
`;

const FormDiv = styled.div`
  grid-column: ${(props) => (props.sectionTitle ? 3 : 1)} / -1;

  width: 100%;
`;

const section = (props) => {
  const { visible = true } = props;
  if (!visible) {
    return null;
  }

  return (
    <Section {...props}>
      {props.sectionTitle ? <Title {...props}>{props.sectionTitle}</Title> : <div></div>}
      <FormDiv {...props}>
        <div title=''>{props.children}</div>
      </FormDiv>
    </Section>
  );
};

export default section;
