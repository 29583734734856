import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import emailImg from '../../assets/images/baseline-mail_outline-24px.svg';

const Content = styled.div`
  border: 1px solid ${props => props.theme.border_line};
  border-radius: 4px;
  background-color: ${props => props.theme.light};
  padding: 36px 16px;
  width: 773px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 16px;
  grid-template-rows: repeat(4, auto);

  h4 {
    color: ${props => props.theme.primary};
    grid-column: 1 / span 8;
    grid-row: 1;
    text-align: center;
  }

  .sub1 {
    color: ${props => props.theme.gray_700};
    grid-column: 1 / span 8;
    grid-row: 2;
    text-align: center;
    line-height: 36px;
  }

  img {
    width: 200px;
    height: 200px;
    margin: 20px auto;
    grid-column: 1 / span 8;
    grid-row: 3;
  }

`


const ConfirmationPage = ({ adminEmail }) => (
  <Content>
    <h4>Thank you!</h4>
    <p className="sub1">
      We have sent the verification link to {adminEmail}. <br />
      It could take up to 20 minutes for verification email to be delivered.<br />
      Please check your email and click on verification link to complete registration.</p>
    <img src={emailImg} alt="email" />
  </Content>
)

const mapStateToProps = state => {
  return {
    adminEmail: state.registration.adminUser.email
  }
}

export default connect(mapStateToProps)(ConfirmationPage)