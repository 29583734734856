import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const NEW_PAYLOAD = 'NEW_PAYLOAD';

export const LOAD_PAYLOAD_LIST_SUCCESS = 'LOAD_PAYLOAD_LIST_SUCCESS';
export const LOAD_PAYLOAD_LIST_FAILED = 'LOAD_PAYLOAD_LIST_FAILED';

export const UPDATE_PAYLOAD_PROPERTY = 'UPDATE_PAYLOAD_PROPERTY';
export const CLOSE_PAYLOAD_FORM = 'CLOSE_PAYLOAD_FORM';

export const EDIT_PAYLOAD = 'EDIT_PAYLOAD';
export const SAVE_PAYLOAD_SUCCESS = 'SAVE_PAYLOAD_SUCCESS';
export const SAVE_PAYLOAD_FAILED = 'SAVE_PAYLOAD_FAILED';

export const CREATE_PAYLOAD = 'CREATE_PAYLOAD';
export const CREATE_PAYLOAD_SUCCESS = 'CREATE_PAYLOAD_SUCCESS';
export const CREATE_PAYLOAD_FAILED = 'CREATE_PAYLOAD_FAILED';

const initialState = {
  all: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_PAYLOAD:
      return {
        ...state,
        form: {
          isNew: true,
          status: 'active',
        },
      };

    case LOAD_PAYLOAD_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_PAYLOAD_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_PAYLOAD:
      return {
        ...state,
        form: state.all.find((x) => x._id.toString() === action.id.toString()),
      };
    case UPDATE_PAYLOAD_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case CLOSE_PAYLOAD_FORM:
      return {
        ...state,
        form: undefined,
      };
    case SAVE_PAYLOAD_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        form: undefined,
      };
    case SAVE_PAYLOAD_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CREATE_PAYLOAD_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: undefined,
      };
    case CREATE_PAYLOAD_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const newPayload = () => {
  return {
    type: NEW_PAYLOAD,
  };
};

export const loadPayloadListSuccess = (data) => {
  return {
    type: LOAD_PAYLOAD_LIST_SUCCESS,
    data,
  };
};

export const loadPayloadListFailed = (error) => {
  return {
    type: LOAD_PAYLOAD_LIST_FAILED,
    error,
  };
};

export const loadPayloadList = () => {
  return (dispatch) => {
    axios
      .get('/payloads', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadPayloadListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPayloadListFailed(error));
      });
  };
};

// Get
export const editPayload = (id) => {
  return {
    type: EDIT_PAYLOAD,
    id,
  };
};

// Update
export const updatePayloadProperty = (data) => {
  return {
    type: UPDATE_PAYLOAD_PROPERTY,
    data,
  };
};

export const closePayloadForm = () => {
  return {
    type: CLOSE_PAYLOAD_FORM,
  };
};

export const savePayloadSuccess = (data) => {
  notification['success']({
    message: i18next.t('payload:control.notification.save_success.message', 'Saved!'),
    description: i18next.t('payload:control.notification.save_success.description', 'Your settings have been updated.'),
    className: 'sub2',
  });
  return {
    type: SAVE_PAYLOAD_SUCCESS,
    data,
  };
};

export const savePayloadFailed = (error) => {
  notification['error']({
    message: i18next.t('payload:control.notification.save_failed_message.message', 'Error!'),
    description: i18next.t('payload:control.notification.save_failed_message.description', 'There is something wrong, please try again'),
    className: 'sub2',
  });
  return {
    type: SAVE_PAYLOAD_FAILED,
    error,
  };
};

export const savePayload = (id, data) => {
  return (dispatch) => {
    axios
      .patch(`/payloads/${id}`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(savePayloadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(savePayloadFailed(error));
      });
  };
};

// Create
export const createPayloadSuccess = (data) => {
  notification['success']({
    message: i18next.t('payload:control.notification.create_success_message.message', 'Successful!'),
    description: i18next.t('payload:control.notification.create_success_message.description', 'New payload has been added.'),
    className: 'sub2',
  });
  return {
    type: CREATE_PAYLOAD_SUCCESS,
    data,
  };
};

export const createPayloadFailed = (error) => {
  notification['error']({
    message: i18next.t('payload:control.notification.create_failed_message.message', 'Error!'),
    description: i18next.t('payload:control.notification.create_failed_message.description', 'There is something wrong, please try again'),
    className: 'sub2',
  });
  return {
    type: CREATE_PAYLOAD_FAILED,
    error,
  };
};

export const createPayload = (data) => {
  return (dispatch) => {
    axios
      .post(`/payloads`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createPayloadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createPayloadFailed(error));
      });
  };
};
