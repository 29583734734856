import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, Modal, Section, Select, Input } from '../../ui';
import useIntl from '../../../hooks/useIntl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { activatedShareTask, clearShareTask, createDefaultSite, loadClientList, loadSiteList } from '../../../redux/shareTask';
import getValidationFormErrorMessage from '../../../util/getValidationFormErrorMessage';
import { isEmpty } from 'lodash';

const TaskCodeGenerator = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, setLanguage } = useIntl(['share-task']);
  const { language, accessToken } = useParams();
  const android = window.Android;
  const decodedAccessToken = decodeURIComponent(accessToken);

  const [controlActivationCode, setControlActivationCode] = useState(false);
  const clientList = useSelector((state) => state.shareTask?.client);
  const siteList = useSelector((state) => state.shareTask?.site);
  const siteDefaultId = siteList?.find((item) => item.defaultSite === true)?._id;
  const activationCodeSuccess = useSelector((state) => state.shareTask?.activationCode);
  const activationCodeError = useSelector((state) => state.shareTask?.error);
  const activationCodeStatusError = activationCodeError?.status;

  const {
    control,
    watch,
    formState: { errors },
    setError,
    handleSubmit,
    reset: onUseResetForm,
  } = useForm({});
  const codeFieldData = watch('code');
  const selectedClientFieldData = watch('client');

  const handleStatusCodeError = (code) => {
    if (code === 427) {
      setError('code', { type: 'incorrect_code' });
    } else if (code === 428) {
      setError('code', { type: 'code_activated_already' });
    }
  };

  useEffect(() => {
    setLanguage(language);
    dispatch(loadClientList(decodedAccessToken));
    // eslint-disable-next-line
  }, [dispatch, decodedAccessToken]);

  useEffect(() => {
    selectedClientFieldData && dispatch(loadSiteList(selectedClientFieldData, decodedAccessToken));
  }, [dispatch, selectedClientFieldData, decodedAccessToken]);

  useEffect(() => {
    if (codeFieldData && siteDefaultId && selectedClientFieldData && controlActivationCode && decodedAccessToken) {
      dispatch(activatedShareTask(codeFieldData, siteDefaultId, selectedClientFieldData, decodedAccessToken));
    }
  }, [dispatch, codeFieldData, siteDefaultId, selectedClientFieldData, controlActivationCode, decodedAccessToken]);

  useEffect(() => {
    if (activationCodeStatusError) {
      handleStatusCodeError(activationCodeStatusError);
    }
    if (activationCodeSuccess) {
      onUseResetForm();
      dispatch(clearShareTask());
      if (android) {
        android.closeWindowTaskCodeActivation();
      }
    }
    setControlActivationCode(false);
    // eslint-disable-next-line
  }, [dispatch, setError, onUseResetForm, activationCodeSuccess, activationCodeError]);

  const handleOnOkClicked = () => {
    setControlActivationCode(true);
    if (isEmpty(siteDefaultId)) {
      dispatch(createDefaultSite(selectedClientFieldData, decodedAccessToken));
    }
  };

  const handleCancelClicked = (e) => {
    e.preventDefault();
    if (android) {
      android.closeWindowTaskCodeActivation();
    }
    onUseResetForm();
  };

  const handleOnClickTitle = () => {
    history.push('/clients');
  };

  const getValidationErrorMessage = getValidationFormErrorMessage(t, 'share-task:modal_task_code_activation', {});

  return (
    <Modal
      id='task-code-activation-modal'
      title={t('share-task:modal_task_code_activation.title')}
      visible={true}
      disabledOverlay={true}
      backgroundColor='rgba(0, 0, 0, 0.09)'
      okText={t('share-task:modal_task_code_activation.confirm_button')}
      okDisabled={controlActivationCode}
      onOk={handleSubmit(handleOnOkClicked)}
      modalFooterAlign='center'
      cancelText={t('share-task:modal_task_code_activation.cancel_button')}
      onCancel={handleCancelClicked}>
      <Section>
        <Field
          title={t('share-task:modal_task_code_activation.form.code.label')}
          isRequired={true}
          errorMessage={getValidationErrorMessage(errors, 'code')}>
          <Controller
            name={'code'}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                id='task-code-activation-code-input'
                className='no-arrow'
                type='number'
                maxlength='6'
                placeholder={t('share-task:modal_task_code_activation.form.code.placeholder')}
                {...field}
              />
            )}
          />
        </Field>
        <Field
          title={t('share-task:modal_task_code_activation.form.client.label')}
          isRequired={true}
          errorMessage={getValidationErrorMessage(errors, 'client')}>
          <Controller
            name={'client'}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                required
                id='task-code-activation-select-client-input'
                title={t('share-task:modal_task_code_activation.form.client.placeholder')}
                isSelectTitleVisible={false}
                selectTitle={t('share-task:modal_task_code_activation.form.client.select_title')}
                handleOnClickTitle={handleOnClickTitle}
                heightSelectOptions='150px'
                options={clientList?.map((item, index) => {
                  return {
                    key: index,
                    value: item._id,
                    text: item.name,
                  };
                })}
                {...field}
              />
            )}
          />
        </Field>
      </Section>
    </Modal>
  );
};

export default TaskCodeGenerator;
