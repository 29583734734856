import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_DRONE_DETAIL_SUCCESS = 'LOAD_DRONE_DETAIL_SUCCESS';
export const LOAD_DRONE_DETAIL_FAILED = 'LOAD_DRONE_DETAIL_FAILED';
export const LOAD_DRONE_STATS_SUCCESS = 'LOAD_DRONE_STATS_SUCCESS';
export const LOAD_DRONE_STATS_FAILED = 'LOAD_DRONE_STATS_FAILED';
export const CLEAR_DRONE_STATS_DETAIL = 'CLEAR_DRONE_STATS_DETAIL';

export const NEW_DRONE = 'NEW_DRONE';

export const LOAD_DRONE_LIST_SUCCESS = 'LOAD_DRONE_LIST_SUCCESS';
export const LOAD_DRONE_LIST_FAILED = 'LOAD_DRONE_LIST_FAILED';

export const UPDATE_DRONE_PROPERTY = 'UPDATE_DRONE_PROPERTY';
export const CLOSE_DRONE_FORM = 'CLOSE_DRONE_FORM';

export const EDIT_DRONE = 'EDIT_DRONE';
export const SAVE_DRONE_SUCCESS = 'SAVE_DRONE_SUCCESS';
export const SAVE_DRONE_FAILED = 'SAVE_DRONE_FAILED';

export const CREATE_DRONE = 'CREATE_DRONE';
export const CREATE_DRONE_SUCCESS = 'CREATE_DRONE_SUCCESS';
export const CREATE_DRONE_FAILED = 'CREATE_DRONE_FAILED';

const initialState = {
  all: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_DRONE_DETAIL_SUCCESS:
      return {
        ...state,
        info: action.data,
      };

    case LOAD_DRONE_DETAIL_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_DRONE_STATS_SUCCESS:
      return {
        ...state,
        stats: action.data,
      };

    case LOAD_DRONE_STATS_FAILED:
      return {
        ...state,
        error: { data: action.error, code: action.error.request.status },
      };

    case CLEAR_DRONE_STATS_DETAIL:
      return {
        ...state,
        stats: undefined,
        info: undefined,
        error: undefined,
      };

    case NEW_DRONE:
      return {
        ...state,
        form: {
          isNew: true,
        },
      };

    case LOAD_DRONE_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_DRONE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_DRONE_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case EDIT_DRONE:
      return {
        ...state,
        form: state.info,
      };
    case CLOSE_DRONE_FORM:
      return {
        ...state,
        form: undefined,
      };
    case SAVE_DRONE_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        info: action.data,
        form: undefined,
      };
    case SAVE_DRONE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CREATE_DRONE_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: undefined,
      };
    case CREATE_DRONE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const loadDroneDetail = (droneId) => {
  return (dispatch) => {
    axios
      .get(`/drones/${droneId}?includeFields=droneModel`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadDroneDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadDroneDetailFailed(error));
      });
  };
};

export const loadDroneDetailSuccess = (data) => {
  return {
    type: LOAD_DRONE_DETAIL_SUCCESS,
    data,
  };
};

export const loadDroneDetailFailed = (error) => {
  return {
    type: LOAD_DRONE_DETAIL_FAILED,
    error,
  };
};

export const loadDroneStats = (serialNumber) => {
  return (dispatch) => {
    axios
      .post(`/report/dronestats/${serialNumber}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadDroneStatsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadDroneStatsFailed(error));
      });
  };
};

export const loadDroneStatsSuccess = (data) => {
  return {
    type: LOAD_DRONE_STATS_SUCCESS,
    data,
  };
};

export const loadDroneStatsFailed = (error) => {
  return {
    type: LOAD_DRONE_STATS_FAILED,
    error,
  };
};

export const clearDroneStatDetail = () => {
  return {
    type: CLEAR_DRONE_STATS_DETAIL,
  };
};

export const newDrone = () => {
  return {
    type: NEW_DRONE,
  };
};

// Load All
export const loadDroneListSuccess = (data) => {
  return {
    type: LOAD_DRONE_LIST_SUCCESS,
    data,
  };
};
export const loadDroneListFailed = (error) => {
  return {
    type: LOAD_DRONE_LIST_FAILED,
    error,
  };
};
export const loadDroneList = () => {
  return (dispatch) => {
    axios
      .get('/drones', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        // TODO: Remove this line when backend is fixed, use temporary solution for now until sunset hgmc legacy
        response.data = response.data.map((drone) => {
          drone.status = drone.status.toLowerCase();
          return drone;
        });
        dispatch(loadDroneListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadDroneListFailed(error));
      });
  };
};

export const editDrone = (id) => {
  return {
    type: EDIT_DRONE,
    id,
  };
};

// Update
export const updateDroneProperty = (data) => {
  return {
    type: UPDATE_DRONE_PROPERTY,
    data,
  };
};

export const closeDroneForm = () => {
  return {
    type: CLOSE_DRONE_FORM,
  };
};

// Create
export const createDroneSuccess = (data) => {
  notification['success']({
    message: i18next.t('drone:control.notification.create_success_message.message'),
    description: i18next.t('drone:control.notification.create_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_DRONE_SUCCESS,
    data,
  };
};
export const createDroneFailed = (error) => {
  if (error.response.status === 420) {
    notification['error']({
      message: i18next.t('drone:control.notification.validate_failed_organization_message.message'),
      description: i18next.t('drone:control.notification.validate_failed_organization_message.description'),
      className: 'sub2',
    });
  } else if (error.response.status === 421) {
    notification['error']({
      message: i18next.t('drone:control.notification.validate_failed_invalid_message.message'),
      description: i18next.t('drone:control.notification.validate_failed_invalid_message.description'),
      className: 'sub2',
    });
  } else {
    notification['error']({
      message: i18next.t('drone:control.notification.create_failed_message.message'),
      description: i18next.t('drone:control.notification.create_failed_message.description'),
      className: 'sub2',
    });
  }
  return {
    type: CREATE_DRONE_FAILED,
    error,
  };
};
export const createDrone = (data) => {
  return (dispatch) => {
    axios
      .post(`/drones`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        // TODO: Remove this line when backend is fixed, use temporary solution for now until sunset hgmc legacy
        response.data.status = response.data.status.toLowerCase();
        dispatch(createDroneSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createDroneFailed(error));
      });
  };
};
