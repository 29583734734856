import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: ${props => props.leadingicon ? '44px' : '12px'};
  padding-right: ${props => props.trailingicon ? '36px' : '12px'};
  border-radius: 4px;
  line-height: 24px;
  border: 1px solid ${props => props.theme.border_text_field};
  box-shadow: 0 0 0 1px transparent;

  &::placeholder {
    color: ${props => props.theme.gray_500};
  }


  &:hover:not(:disabled):not(:focus) {
    border: 1px solid ${props => props.theme.primary_800};

    ~ .trailingicon, ~ .leadingicon, ~ label.fieldlabel {
      color: ${props => props.theme.primary_800};
    }
  }

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.secondary_400};
    box-shadow: 0 0 0 1px ${props => props.theme.secondary_400};
    ~ .trailingicon, ~ .leadingicon, ~ label.fieldlabel {
      color: ${props => props.theme.secondary_400};
    }
  }

  ~ .trailingicon {
    position: absolute;
    top: 50%;
    right: 12px;
  }

  ~ .leadingicon {
    position: absolute;
    top: 50%;
    left: 12px;
  }

  ~ .erroricon {
    position: absolute;
    top: 40%;
    right: 12px;
    display: none;
  }

  ~ label.fieldlabel {
    color: ${props => props.theme.gray_700};
  }
`

export default props => (
  <React.Fragment>
    <TextArea {...props} className="form-control sub2" />
    <i className="material-icons erroricon">error</i>
    {props.trailingicon &&
      <i className="material-icons trailingicon">{props.trailingicon}</i>
    }
    {props.leadingicon &&
      <i className="material-icons leadingicon">{props.leadingicon}</i>
    }
  </React.Fragment>
);