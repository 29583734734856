import React from 'react';
import { Result } from 'antd';
import useIntl from './hooks/useIntl';
import styled from 'styled-components';

const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnhandledErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useIntl('notfound');

  return (
    <Screen>
      <Result status={500} subTitle={t('notfound:error.title')} extra={<div>{t('notfound:error.suggestion')}</div>} />
    </Screen>
  );
};

export default UnhandledErrorFallback;
