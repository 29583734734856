import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { updateAdminAccountProperty, goToNextStep, validateRegistrationInfo, EMAIL_FORMAT_REGEX } from '../../redux/registration';
import Icon from '../../assets/images/admin-user-form-icon.svg';
import { Field, Input, Button } from '../ui';
import { isEqual } from 'lodash';

const Content = styled.div`
  border: 1px solid ${(props) => props.theme.border_line};
  border-radius: 4px;
  background-color: ${(props) => props.theme.light};
  padding: 36px 16px;
  width: 773px;
  margin: auto;
`;
const Header = styled.div`
  display: flex;
  height: 130px;
  img {
    height: 60px;
    margin-right: 24px;
  }
  h4 {
    color: ${(props) => props.theme.primary};
  }
  p {
    color: ${(props) => props.theme.gray_700};
  }
`;
const Body = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 16px;
`;

const BodyField = styled.div`
  grid-column: 2 / span 4;
`;

const SubmitButton = styled(Button).attrs({ colorTheme: 'primary' })`
  grid-column: 2 / span 4;
`;

class AdminUserForm extends Component {
  state = {
    errorField: {},
  };

  componentDidMount() {
    const {
      organization: { isIndividualUser, name: organizationName },
    } = this.props;

    if (isIndividualUser) {
      this.props.updateProperty({ name: organizationName });
    }
  }

  componentDidUpdate(prevProps) {
    const { validateRegistrationResult, goToNextStep } = this.props;
    if (validateRegistrationResult !== null && !isEqual(prevProps.validateRegistrationResult, validateRegistrationResult)) {
      if (validateRegistrationResult.isValidateSuccess) {
        goToNextStep();
      } else if (Object.keys(validateRegistrationResult.errorField).length > 0) {
        this.setState({ errorField: validateRegistrationResult.errorField });
      }
    }
  }

  onChangeHandler = (field) => (e) => {
    const { errorField } = this.state;
    if (field === 'email') {
      this.setState({
        errorField: { ...errorField, [field]: EMAIL_FORMAT_REGEX.test(e.target.value) ? undefined : 'Email address is invalid.' },
      });
    } else if (errorField[field]) {
      this.setState({ errorField: { ...errorField, [field]: undefined } });
    }
    this.props.updateProperty({ [field]: e.target.value });
  };

  onSubmit = () => {
    const { adminUser, validateRegistrationInfo } = this.props;
    validateRegistrationInfo(adminUser);
  };

  render() {
    const { onChangeHandler, onSubmit } = this;
    const {
      adminUser,
      error,
      organization: { isIndividualUser, name: organizationName },
    } = this.props;

    const { errorField } = this.state;
    return (
      <Content>
        <Header>
          <img src={Icon} alt='HiveGrid Logo' />
          <div>
            <h4>Create Admin Account</h4>
            <p className='sub1'>
              This user account will be able to administer <br /> this new HiveGrid system.
            </p>
          </div>
        </Header>
        <hr />
        <Body>
          <BodyField>
            <Field title='Name' isRequired={true} errorMessage={errorField.name}>
              <Input
                disabled={isIndividualUser}
                placeholder='Enter your name'
                value={isIndividualUser ? organizationName : adminUser.name}
                onChange={onChangeHandler('name')}
                id='input-reg-admin-name'
              />
            </Field>
            <Field
              title='Admin Email'
              isRequired={true}
              errorMessage={errorField.email || (error && error.message)}
              infoText='Email will be used as a default username'>
              <Input
                placeholder='e.g. contact@company.com'
                value={adminUser.email}
                onChange={onChangeHandler('email')}
                autocomplete='off'
                id='input-reg-admin-email'
              />
            </Field>
            <Field title='Password' isRequired={true} errorMessage={errorField.password}>
              <Input
                type='password'
                placeholder='Enter your password'
                value={adminUser.password}
                onChange={onChangeHandler('password')}
                autocomplete='off'
                id='input-reg-admin-password'
              />
            </Field>
            <Field title='Confirm Password' isRequired={true} errorMessage={errorField.confirmPassword}>
              <Input
                type='password'
                placeholder='Confirm your password'
                value={adminUser.confirmPassword}
                onChange={onChangeHandler('confirmPassword')}
                autocomplete='off'
                id='input-reg-admin-confirmpassword'
              />
            </Field>
          </BodyField>
          <SubmitButton onClick={onSubmit} style={{ width: '100%' }} id='button-reg-continue'>
            CONTINUE
          </SubmitButton>
        </Body>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminUser: state.registration.adminUser,
    validateRegistrationResult: state.registration.validateRegistrationResult,
    error: state.registration.error,
    organization: state.registration.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProperty: (data) => dispatch(updateAdminAccountProperty(data)),
    goToNextStep: () => dispatch(goToNextStep()),
    validateRegistrationInfo: (adminUser) => dispatch(validateRegistrationInfo(adminUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserForm);
