export const languageMapper = {
  English: 'en',
  Thai: 'th',
}

export default function (language) {
  if (language) {
    const availableLang = Object.values(languageMapper);
    if (availableLang.includes(language)) {
      return language;
    }
    if (language in languageMapper) {
      return languageMapper[language];
    }
  }
  return languageMapper.English;
}