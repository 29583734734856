import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { rgba } from 'polished';
import { HEADERSTATUS } from '../../../../enums/datepickerHeaderStatus';

const HeaderPicker = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px;
  border-bottom: 1px solid ${(props) => props.theme.border_line};
`;

const YearMonthDisplay = styled.div.attrs({ className: 'sub2' })`
  color: ${(props) => props.theme.gray_900};
  cursor: pointer;
`;

const NavigationButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${(props) => !props.disabled && props.theme.gray_900};
    background: ${(props) => rgba(props.theme.primary_700, 0.04)};
  }
  &:active {
    background: ${(props) => rgba(props.theme.primary_700, 0.24)};
  }
`;

const TextHeader = styled.span`
  margin-right: 5px;
  margin-left: 5px;
`;

export default ({ id, pendingMonthYear, headerStatus, onPendingMonthYearValueChanged, onHeaderClicked }) => {
  const pendingMonthYears = dayjs(pendingMonthYear).clone();

  const handleOnNavigationClicked = (direction) => {
    let unit, step;
    if (!headerStatus) {
      step = 1;
      unit = 'month';
    } else if (headerStatus === HEADERSTATUS.MONTH) {
      step = 1;
      unit = 'year';
    } else if (headerStatus === HEADERSTATUS.YEAR) {
      step = 10;
      unit = 'year';
    }
    if (direction === 'next') {
      onPendingMonthYearValueChanged(pendingMonthYears.add(step, unit), null, direction);
    } else if (direction === 'prev') {
      onPendingMonthYearValueChanged(pendingMonthYears.subtract(step, unit), null, direction);
    }
  };

  return (
    <HeaderPicker>
      <NavigationButton id={`button-datepicker-${id}-prev`} onClick={() => handleOnNavigationClicked('prev')}>
        <i className='material-icons'>arrow_left</i>
      </NavigationButton>
      <YearMonthDisplay>
        {headerStatus === null ? (
          [
            <TextHeader id={`text-header-datepicker-${id}-month`} onClick={() => onHeaderClicked(HEADERSTATUS.MONTH)}>
              {pendingMonthYears.format('MMMM')}
            </TextHeader>,
            <TextHeader id={`text-header-datepicker-${id}-year`} onClick={() => onHeaderClicked(HEADERSTATUS.YEAR)}>
              {pendingMonthYears.format('YYYY')}
            </TextHeader>,
          ]
        ) : headerStatus === HEADERSTATUS.MONTH ? (
          <TextHeader id={`text-header-datepicker-${id}-year`} onClick={() => onHeaderClicked(HEADERSTATUS.YEAR)}>
            {pendingMonthYears.format('YYYY')}
          </TextHeader>
        ) : (
          headerStatus === HEADERSTATUS.YEAR && (
            <TextHeader id={`text-header-datepicker-${id}-range-year`} onClick={() => onHeaderClicked(HEADERSTATUS.YEAR)}>
              {pendingMonthYears.subtract(10, HEADERSTATUS.YEAR).format('YYYY').substring(0, 3) + 9} -{' '}
              {pendingMonthYears.add(10, HEADERSTATUS.YEAR).format('YYYY').substring(0, 3) + 0}
            </TextHeader>
          )
        )}
      </YearMonthDisplay>
      <NavigationButton id={`button-datepicker-${id}-next`} onClick={() => handleOnNavigationClicked('next')}>
        <i className='material-icons'>arrow_right</i>
      </NavigationButton>
    </HeaderPicker>
  );
};
