import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Loading from './components/ui/feedback/Loader';
import { verifyJWT } from './redux/auth';
import './App.css';
import RegistrationLayout from './components/registration/RegistrationLayout';
import CongratulationPage from './components/registration/CongratulationPage';
import TermsOfService from './components/legal/TermsOfService';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import ResetPasswordByKey from './components/ResetPasswordByKey';
import TaskCodeGenerator from './components/sharetask/TaskCodeGenerator';
import TaskCodeActivation from './components/sharetask/TaskCodeActivation';
import { AxiosErrorHandlerComp } from './axios';

const Home = lazy(() => import('./components/Home'));
const LoginPage = lazy(() => import('./components/login'));

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loading />}>
        <AxiosErrorHandlerComp>
          <Switch>
            <Route
              path='/login'
              render={() => {
                if (this.props.isAuthenticate === undefined) {
                  this.props.verifyJWT();
                  return null;
                } else if (this.props.isAuthenticate) {
                  return <Redirect to='/' />;
                } else {
                  return <LoginPage />;
                }
              }}
            />
            <Route path='/registration' component={RegistrationLayout} />
            <Route path='/terms-of-service' component={TermsOfService} />
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/verification' component={CongratulationPage} />
            <Route path='/reset-password-by-key' component={ResetPasswordByKey} />
            <Route
              path='/request-reset-password-email'
              render={() => {
                return <LoginPage subComponent='request-reset-password' />;
              }}
            />
            <Route path='/sharetask/activation/:language/:accessToken' component={TaskCodeActivation} />
            <Route path='/sharetask/:language/:taskId/:accessToken' component={TaskCodeGenerator} />
            <Route
              path='/'
              render={() => {
                if (this.props.isAuthenticate === undefined) {
                  this.props.verifyJWT();
                  return null;
                } else if (this.props.isAuthenticate) {
                  return <Home />;
                } else return <Redirect to='/login' />;
              }}
            />
          </Switch>
        </AxiosErrorHandlerComp>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticate: state.auth.isAuthenticate,
    isRequiredChangePassword: state.auth.isRequiredChangePassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyJWT: () => dispatch(verifyJWT()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
