// import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

// INFO: disable color prevent overlap with <Field/> when error message
const StyledPhoneInput = styled(PhoneInput)`
  border: ${(props) => (props.disableColor ? 'none' : `1px solid ${props.theme.primary_50}`)};
  border-radius: 4px;

  input.form-control {
    width: 100%;
    height: 48px;
    padding-left: 72px;
    line-height: 24px;
    border: none;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 400;
    ::placeholder {
      /* default placeholder color */
      color: ${(props) => props.theme.gray_500};
    }

    z-index: 100

    :hover {
      border: ${(props) => (props.disableColor ? 'none' : `1px solid ${props.theme.primary}`)};
    }

    :focus-within {
      outline: none;
      border: ${(props) => (props.disableColor ? 'none' : `1px solid ${props.theme.secondary}`)};
      box-shadow: 0 0 0 1px ${(props) => props.theme.secondary};
    }
  }

  .flag-dropdown {
    border: none;
    height: 44px;
    margin-top: 2px;
    margin-left: 2px;
    width: 60px;
    background-color: ${(props) => props.theme.light};
    .selected-flag {
      width: 100%;
      padding-left: 16px;
    }
  }
`;
export default StyledPhoneInput;
