import { get } from 'lodash';

const getMessageRuleParam = (validationRule) => {
  const rule = validationRule?.rule;

  switch (rule) {
    case 'range': {
      const lowerBound = validationRule.conditions.all.find((r) => r.operator === 'greaterThan' || r.operator === 'greaterThanInclusive');
      const upperBound = validationRule.conditions.all.find((r) => r.operator === 'lessThan' || r.operator === 'lessThanInclusive');

      return { lowerBound: lowerBound.value, upperBound: upperBound.value };
    }

    case 'min': {
      const lowerBound = validationRule.conditions.all.find((r) => r.operator === 'greaterThan' || r.operator === 'greaterThanInclusive');

      return { lowerBound: lowerBound.value };
    }

    default:
      return {};
  }
};

const getValidationFormErrorMessage = (t, namespace, context) => (errors, fieldName) => {
  const error = get(errors, fieldName);
  if (!error) {
    return null;
  }

  const lastPathFieldName = fieldName.split('.').length >= 0 ? fieldName.split('.').pop() : fieldName;

  const param = context[lastPathFieldName]?.name ?? lastPathFieldName;
  const rule = context[lastPathFieldName]?.rule;

  const exist = namespace.includes(':');

  return exist
    ? t(`${namespace}.form.${param}.validation.${error.type}`, getMessageRuleParam(rule))
    : t(`${namespace}:form.${param}.validation.${error.type}`, getMessageRuleParam(rule));
};

export default getValidationFormErrorMessage;
