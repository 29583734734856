import React from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';

const TabWrapper = styled(Tabs)`
  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 6px 6px 0 0 !important;
    border-color: ${(props) => props.theme.border_line};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.primary};
  }
`;

const tab = (props) => <TabWrapper tabBarStyle={{ marginBottom: 0 }} {...props}>{props.children}</TabWrapper>;

export default tab;
