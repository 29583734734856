export const ERROR = {
  CODE_REQUIRED: 'CODE_REQUIRED',
  NAME_REQUIRED: 'NAME_REQUIRED',
  START_DATE_REQUIRED: 'START_DATE_REQUIRED',
  SOLUTION_REQUIRED: 'SOLUTION_REQUIRED',
  TYPE_REQUIRED: 'TYPE_REQUIRED',
  SURVEY_LIST_REQUIRED: 'SURVEY_LIST_REQUIRED',

  //TODO: This error type must be gone since it is from external system setting
  CLIENT_REQUIRED: 'CLIENT_REQUIRED'
};