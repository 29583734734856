import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: grid;
  position: relative;
  grid-column: 1 / span 12;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    background: ${props => props.theme.border_line};
    left: 8px;
    bottom: -1px;
  }

`
const SpaceBlock = styled.div`
  grid-column: 1 / span 2;
  padding-left: 16px;
  padding-top: 16px;
`

const FormDiv = styled.div`  
  /* padding-top: ${props => props.title ? '16px' : '0px'}; */
  padding-top: 16px;
  padding-right: 16px;
  grid-column: 3 / -1;

  > * {
    margin-bottom: 16px;
  }
`

const section = props => {
  return (
    <Section {...props}>
      <SpaceBlock />
      <FormDiv {...props}>{props.children}</FormDiv>
    </Section>
  );
}

export default section