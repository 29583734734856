import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

const { TabPane } = Tabs;

const Wrapper = styled.div.attrs({ id: 'wrapper' })`
  position: relative;
  display: flex;
  padding: ${(props) => props.fullContent ? '0px' : '12px'};
  overflow-y: hidden;
  height: 100%;

  border-style: solid;
  border-width: 0px 1px 1px 1px;
  border-color: ${(props) => props.theme.border_line};

  background: ${(props) => props.theme.white};

  width: 100%;
`;

const tabPane = (props) => (
  <TabPane {...props}>
    <Wrapper fullContent={props.fullContent} >{props.children}</Wrapper>
  </TabPane>
);

export default tabPane;
