import axios from '../axios';

// Actions
export const LOAD_UNIT_LIST = 'LOAD_UNIT_LIST';
export const LOAD_UNIT_LIST_SUCCESS = 'LOAD_UNIT_LIST_SUCCESS';
export const LOAD_UNIT_LIST_FAILED = 'LOAD_UNIT_LIST_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_UNIT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_UNIT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
export const loadUnitListSuccess = (data) => {
  return {
    type: LOAD_UNIT_LIST_SUCCESS,
    data,
  };
};

export const loadUnitListFailed = (error) => {
  return {
    type: LOAD_UNIT_LIST_FAILED,
    error,
  };
};

export const loadUnitList = () => {
  return (dispatch) => {
    axios
      .get('/units', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadUnitListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadUnitListFailed(error));
      });
  };
};
