import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { submitRegistration } from '../../redux/registration';
import { loadLegalContentList } from '../../redux/policy';
import parser from 'html-react-parser';
import { Button } from '../ui';

const Content = styled.div`
  border: 1px solid ${(props) => props.theme.border_line};
  border-radius: 4px;
  background-color: ${(props) => props.theme.light};
  padding: 36px 16px;
  width: 773px;
  margin: auto;
`;

const Body = styled.div``;

const SubmitButton = styled(Button).attrs({ colorTheme: 'primary' })`
  grid-column: 2 / span 4;
`;

const PolicyContent = styled.div`
  max-height: 60vh;
  min-height: 300px;
  overflow: auto;
  margin-bottom: 20px;
`;

class ReviewPrivacyPolicyPage extends Component {
  componentDidMount() {
    this.props.loadLegalContentList();
  }

  onSubmit = () => {
    const { adminUser, submitRegistration, organizationInfo, legalContents } = this.props;
    const policyContent = legalContents ? legalContents.find((e) => e.type === 'PRIVACYPOLICY') : null;

    submitRegistration({
      organization: organizationInfo,
      adminUser: { ...adminUser, confirmPassword: undefined },
      legalContent: { legalContentId: policyContent._id },
    });
  };

  render() {
    const { legalContents } = this.props;
    const policyContent = legalContents ? legalContents.find((e) => e.type === 'PRIVACYPOLICY') : null;
    return (
      <Content>
        <Body>
          <PolicyContent>
            {policyContent &&
              parser(localStorage.getItem('i18nextLng') === 'th' ? policyContent.translation.th || '' : policyContent.content || '')}
          </PolicyContent>
          <SubmitButton onClick={this.onSubmit} style={{ width: '100%' }} id='button-reg-accept&sent'>
            ACCEPT & SEND CONFIRMATION
          </SubmitButton>
        </Body>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    legalContents: state.policy.legalContents,
    organizationInfo: state.registration.organization,
    adminUser: state.registration.adminUser,
    error: state.registration.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadLegalContentList: () => dispatch(loadLegalContentList()),
    submitRegistration: (data) => dispatch(submitRegistration(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPrivacyPolicyPage);
