import axios from '../axios';

// Actions
export const LOAD_EXTERNAL_SYSTEM_SUCCESS = 'LOAD_EXTERNAL_SYSTEM_SUCCESS';
export const LOAD_EXTERNAL_SYSTEM_FAILED = 'LOAD_EXTERNAL_SYSTEM_FAILED';

export const LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_SUCCESS = 'LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_SUCCESS';
export const LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_FAILED = 'LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        all: action.data,
      };

    case LOAD_EXTERNAL_SYSTEM_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_SUCCESS:
      return {
        ...state,
        moduleSettings: action.data,
      };

    case LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

export const loadExternalSystemSuccess = (data) => {
  return {
    type: LOAD_EXTERNAL_SYSTEM_SUCCESS,
    data,
  };
};

export const loadExternalSystemFailed = (error) => {
  return {
    type: LOAD_EXTERNAL_SYSTEM_FAILED,
    error,
  };
};

// Action Creators
export const loadExternalSystems = () => {
  return (dispatch) => {
    axios
      .get(`/externalsystems`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadExternalSystemSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadExternalSystemFailed(error));
      });
  };
};

export const loadExternalSystemModuleSettingSuccess = (data) => {
  return {
    type: LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_SUCCESS,
    data,
  };
};

export const loadExternalSystemModuleSettingFailed = (error) => {
  return {
    type: LOAD_EXTERNAL_SYSTEM_MODULE_SETTING_FAILED,
    error,
  };
};

export const loadExternalSystemModuleSettings = (externalSystemCode, module) => {
  return (dispatch) => {
    const codes = Array.isArray(externalSystemCode) ? externalSystemCode : [externalSystemCode];
    Promise.all(
      codes.map((c) =>
        axios.get(`/externalSystems/${c}/module/${module}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        })
      )
    )
      .then((response) => {
        dispatch(loadExternalSystemModuleSettingSuccess([].concat(...response.map((res) => res.data || []))));
      })
      .catch((error) => dispatch(loadExternalSystemModuleSettingFailed(error)));
  };
};
