import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_ANALYTICS_RESULT_SUCCESS = 'LOAD_ANALYTICS_RESULT_SUCCESS';
export const LOAD_ANALYTICS_RESULT_FAILED = 'LOAD_ANALYTICS_RESULT_FAILED';

export const CLEAR_ANALYTIC_RESULTS = 'CLEAR_ANALYTIC_RESULTS';

const initialState = {
  all: [],
  resultList: [],
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ANALYTICS_RESULT_SUCCESS:
      return {
        ...state,
        resultList: action.data,
      };

    case LOAD_ANALYTICS_RESULT_FAILED:
      return {
        ...state,
        resultList: [],
      };

    case CLEAR_ANALYTIC_RESULTS:
      return {
        ...state,
        resultList: [],
        secondaryResultList: [],
      };

    default:
      return state;
  }
}

const getAnalyticResultURL = (conditions = {}, fields = []) => {
  const includeFields = fields.length > 0 ? `includeFields=${fields.join(',')}` : '';
  const conditionString = Object.keys(conditions)
    .map((k) => `${k}=${conditions[k]}`)
    .join('&');
  const queryString = [includeFields, conditionString].filter(Boolean).join('&');

  return `/analytics/results?${queryString}`;
};

export const loadAnalyticResultSuccess = (data) => {
  return {
    type: LOAD_ANALYTICS_RESULT_SUCCESS,
    data,
  };
};

export const loadAnalyticResultFailed = (error) => {
  notification['error']({
    message: i18next.t('analytic:control.notification.load_result_list_failed.message'),
    description: i18next.t('analytic:control.notification.load_result_list_failed.description'),
  });
  return {
    type: LOAD_ANALYTICS_RESULT_FAILED,
    error,
  };
};

export const loadAnalyticResults = (
  conditions = {},
  fields = [],
  loadAnalyticResultSuccessFunc = null,
  loadAnalyticResultFailedFunc = null
) => {
  const loadSuccessFunc = loadAnalyticResultSuccessFunc ? loadAnalyticResultSuccessFunc : loadAnalyticResultSuccess;
  const loadFailedFunc = loadAnalyticResultFailedFunc ? loadAnalyticResultFailedFunc : loadAnalyticResultFailed;

  const resultTypes = Array.isArray(conditions['resultTypes']) ? conditions['resultTypes'] : [conditions['resultTypes']];

  return async (dispatch) => {
    const result = Array.prototype.concat.apply(
      [],
      await Promise.all(
        resultTypes.flatMap(async (c) => {
          const axiosCondition = { ...conditions };
          axiosCondition['externalSystemCode'] = c.externalSystemCode;
          axiosCondition['resultType'] = c.resultType;
          delete axiosCondition.resultTypes;

          const url = getAnalyticResultURL(axiosCondition, fields);

          try {
            const res = await axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
            return res.data || res.data.length > 0 ? res.data : [];
          } catch (error) {
            dispatch(loadFailedFunc(error));
          }
        })
      )
    );

    dispatch(loadSuccessFunc(result));
  };
};

export const clearAnalyticResults = () => {
  return {
    type: CLEAR_ANALYTIC_RESULTS,
  };
};
