import React, { Component } from 'react';
import styled from 'styled-components';
import RelativePortal from '../../utils/relativePortal/RelativePortal';
import { SingleLineText, DoubleLineText, ImgWithSingleLineText, ImgWithDoubleLineText } from './preset';
import Input from '../Input';
import _ from 'lodash';

const SelectBox = styled.div`
  border-radius: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  user-select: none;
  cursor: default;

  background-color: ${(props) => (props.disabled ? props.theme.dark : props.theme.light)};
  color: ${(props) =>
    props.disabled || typeof props.value === 'undefined' || props.value === null ? props.theme.gray_500 : props.theme.gray_700};
`;

const HeaderBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const TitleBox = styled.div.attrs({ className: 'select-title sub1' })`
  grid-column: 1;
`;

const WrapperBox = styled.div.attrs({ className: 'select-title sub1' })`
  grid-column: 2;
  display: flex;
  justify-content: right;
`;

const IconAdd = styled.i.attrs({ className: 'material-icons' })`
  cursor: pointer;
  width: 10%;
  color: ${(props) => props.theme.gray_600} !important;
`;

const SearchBox = styled.div`
  position: relative;
  padding: 12px;

  .trailingicon {
    top: calc(50% - 12px);
    right: 24px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.isDoubleLine ? '64px' : '48px')};
  background-color: ${(props) => props.theme.light};
  .form-control {
    border: ${(props) => (props.isOpen ? `1px solid ${props.theme.secondary_400}` : `1px solid ${props.theme.border_select}`)};
    box-shadow: 0 0 0 1px ${(props) => (props.isOpen ? props.theme.secondary_400 : 'transparent')};
  }
  .deleteicon {
    display: ${(props) => (props.value && props.deleteable ? 'inline-block' : 'none')};
    position: absolute;
    top: calc(50% - 8px);
    right: 40px;
    color: ${(props) => props.isOpen && props.theme.secondary_400};
    user-select: none;
    cursor: default;
    font-size: 18px;

    &:hover {
      color: ${(props) => props.theme.primary_800};
    }
    &:active {
      color: ${(props) => props.theme.primary_400};
    }
  }
  .trailingicon {
    position: absolute;
    top: calc(50% - 12px);
    right: 12px;
    color: ${(props) => props.isOpen && props.theme.secondary_400};
    user-select: none;
    cursor: default;
  }
  &:hover {
    .form-control {
      border: 1px solid ${(props) => (props.isOpen ? props.theme.secondary_400 : props.theme.primary_800)};
    }

    ~ label,
    .trailingicon {
      color: ${(props) => (props.isOpen ? props.theme.secondary_400 : props.theme.primary_800)};
    }
  }

  ~ label {
    color: ${(props) => (props.isOpen ? props.theme.secondary_400 : props.theme.gray_700)};
  }
`;

const SearchInput = styled(Input)`
  height: 36px;
`;

const TitleWrapper = styled.div`
  padding-right: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropDownWrapper = styled.div`
  max-height: 368px;
  background-color: ${(props) => props.theme.light};
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px;
  z-index: 1;
  cursor: default;
  user-select: none;

  .select-title {
    padding: 12px;
    border-bottom: 1px solid ${(props) => props.theme.border_line};
  }

  .select-body {
    max-height: ${(props) => (props.heightSelectOptions ? props.heightSelectOptions : '246px')};
    overflow-y: auto;

    .select-option {
      padding-left: 12px;
      margin-right: 12px;
      margin-left: 12px;
      min-height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-bottom: 1px solid ${(props) => props.theme.gray_300};

      :hover {
        background-color: ${(props) => props.theme.primary_50};
      }

      i.checked {
        right: 12px;
        color: ${(props) => props.theme.secondary_400};
      }

      .space-for-icon {
        min-width: 24px;
      }
    }
  }

  .select-footer {
    padding-left: 24px;
  }
`;

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      value: props.value,
      search: '',
      filteredOptions: [],
      loading: false,
    };

    this._setShowAsyncTimer = null;

    this._handleToggle = () => {
      const { isOpen } = this.state;
      this._setShowAsync(!isOpen);
    };

    this._handleHide = () => {
      this._setShowAsync(false);
    };
  }

  static getDerivedStateFromProps(props) {
    if ('value' in props) {
      return { value: props.value };
    }

    return null;
  }

  onChange = async (event) => {
    await this.setState({ search: event.target.value });
    await this.setState({ filteredOptions: [] });
    this.setState({ loading: true });
    event.persist();

    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        const { search } = this.state;
        this.search(search);
      }, 1000);
    }
    this.debouncedFn();
  };

  onSearchBoxFocus = (event) => {
    event.target.select();
  };

  onEscapeKeyPressed = (event) => {
    if (event.keyCode === 27) {
      if (this.state.isOpen) {
        this._handleHide();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onEscapeKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscapeKeyPressed, false);
  }

  search = (searchString) => {
    const { options } = this.props;
    const filteredOptions = options.filter((option) => option.text.toLowerCase().includes(searchString.toLowerCase()));
    this.setState({ filteredOptions });
    this.setState({ loading: false });
  };

  handleChangeValue = (value) => () => {
    const { onChange } = this.props;
    if (!('value' in this.props)) {
      this.setState({ value });
    }
    if (onChange) {
      onChange({ target: { value } });
    }
    this.setState({ isOpen: false });
  };

  clearValue = () => {
    const { onChange } = this.props;
    this.setState({ value: null });
    onChange({ target: { value: null } });
  };

  optionRender(data, isInLine = false, isSelectBox = false) {
    const { preset, renderOption } = this.props;
    if (!data) {
      return null;
    }
    if (renderOption) {
      return renderOption(data);
    } else if (preset) {
      switch (preset) {
        case 'singlelinetext':
          return <SingleLineText data={data} isInLine={isInLine} />;
        case 'doublelinetext':
          return <DoubleLineText data={data} />;
        case 'singlelinetextdoublelineoption':
          return isSelectBox ? <SingleLineText data={data} isInLine={isInLine} /> : <DoubleLineText data={data} />;
        case 'imgwithsinglelinetext':
          return <ImgWithSingleLineText data={data} isInLine={isInLine} />;
        case 'imgwithdoublelinetext':
          return <ImgWithDoubleLineText data={data} />;
        default:
          return null;
      }
    } else {
      return <SingleLineText data={data} isInLine={isInLine} />;
    }
  }

  _setShowAsync(show) {
    clearTimeout(this._setShowAsyncTimer);
    this._setShowAsyncTimer = setTimeout(() => {
      this.setState({ isOpen: show });
    }, 0);
  }

  render() {
    const {
      title,
      options,
      disabled,
      deleteable = false,
      id,
      searchable = false,
      className,
      preset,
      isSelectTitleVisible,
      selectTitle,
      handleOnClickTitle,
      heightSelectOptions,
    } = this.props;
    const { isOpen, value, search, filteredOptions, loading } = this.state;
    const renderOptions = search ? filteredOptions : options;

    return (
      <Wrapper
        isDoubleLine={preset === 'doublelinetext' || preset === 'imgwithdoublelinetext'}
        isOpen={isOpen}
        value={value}
        deleteable={deleteable}
        className={className}>
        <SelectBox id={id} className='form-control sub2' disabled={disabled} onClick={disabled ? null : this._handleToggle} value={value}>
          {typeof value === 'undefined' || value === null ? (
            <TitleWrapper>{title}</TitleWrapper>
          ) : (
            this.optionRender(
              options?.find((x) => _.isEqual(value, x.value)),
              true,
              true
            )
          )}
        </SelectBox>
        <i className='material-icons deleteicon' onClick={disabled ? null : this.clearValue.bind(this)}>
          close
        </i>
        <i className='material-icons trailingicon' onClick={disabled ? null : this._handleToggle}>
          {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </i>
        {isOpen && (
          <RelativePortal component='div' left={0} top={8} right={0} fullWidth={true} onOutClick={this._handleHide} zIndex={200}>
            <DropDownWrapper heightSelectOptions={heightSelectOptions}>
              {isSelectTitleVisible && selectTitle ? (
                <HeaderBoxWrapper>
                  <TitleBox>{selectTitle}</TitleBox>
                  {handleOnClickTitle && (
                    <WrapperBox>
                      <IconAdd onClick={handleOnClickTitle}>added</IconAdd>
                    </WrapperBox>
                  )}
                </HeaderBoxWrapper>
              ) : (
                <div className='select-title sub1'>{title}</div>
              )}

              {searchable && (
                <SearchBox>
                  <SearchInput
                    placeholder={'Search'}
                    trailingicon={'search'}
                    onClick={this.onSearchBoxFocus}
                    onChange={this.onChange}
                    value={this.state.search}
                  />
                </SearchBox>
              )}

              <div className='select-body'>
                {loading && search && (
                  <div className='select-option sub1' style={{ justifyContent: 'center' }}>
                    Fetching data ...
                  </div>
                )}
                {renderOptions &&
                  renderOptions.map((option) => (
                    <div className='select-option sub1' key={option.key} id={option.id} onClick={this.handleChangeValue(option.value)}>
                      {this.optionRender(option)}
                      {option.value === value && <i className='material-icons checked'>check_circle</i>}
                      {option.value !== value && <div className='space-for-icon' />}
                    </div>
                  ))}
              </div>
            </DropDownWrapper>
          </RelativePortal>
        )}
      </Wrapper>
    );
  }
}

export default Select;
