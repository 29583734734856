import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';
import Collator from '../collator';

// Actions
export const LOAD_ROUTE_LIST_SUCCESS = 'LOAD_ROUTE_LIST_SUCCESS';
export const LOAD_ROUTE_LIST_FAILED = 'LOAD_ROUTE_LIST_FAILED';

export const SELECT_ROUTE = 'SELECT_ROUTE';
export const UPDATE_ROUTE_PROPERTY = 'UPDATE_ROUTE_PROPERTY';
export const CLOSE_ROUTE_FORM = 'CLOSE_ROUTE_FORM';

export const NEW_ROUTE = 'NEW_ROUTE';
export const CREATE_ROUTE_SUCCESS = 'CREATE_ROUTE_SUCCESS';
export const CREATE_ROUTE_FAILED = 'CREATE_ROUTE_FAILED';

export const EDIT_ROUTE = 'EDIT_ROUTE';
export const SAVE_ROUTE_SUCCESS = 'SAVE_ROUTE_SUCCCESS';
export const SAVE_ROUTE_FAILED = 'SAVE_ROUTE_FAILED';

export const DELETE_ROUTE = 'EDIT_ROUTE';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCCESS';
export const DELETE_ROUTE_FAILED = 'DELETE_ROUTE_FAILED';

export const CLEAR_SELECTED_ROUTE = 'CLEAR_SELECTED_ROUTE';
export const CLEAR_ROUTE_LIST = 'CLEAR_ROUTE_LIST';

export const LOAD_ROUTE_SUCCESS = 'LOAD_ROUTE_SUCCESS';
export const LOAD_ROUTE_FAILED = 'LOAD_ROUTE_FAILED';

export const IMPORT_ROUTE_KML_FILE = 'IMPORT_ROUTE_KML_FILE';
export const IMPORT_ROUTE_KML_FILE_SUCCESS = 'IMPORT_ROUTE_KML_FILE_SUCCESS';
export const IMPORT_ROUTE_KML_FILE_FAILED = 'IMPORT_ROUTE_KML_FILE_FAILED';
export const CLOSE_ROUTE_KML_DIALOG = 'CLOSE_ROUTE_KML_DIALOG';

export const SUBMIT_ROUTE_KML_FILE = 'SUBMIT_ROUTE_KML_FILE';
export const SUBMIT_ROUTE_KML_FILE_SUCCESS = 'SUBMIT_ROUTE_KML_FILE_SUCCESS';
export const SUBMIT_ROUTE_KML_FILE_FAILED = 'SUBMIT_ROUTE_KML_FILE_FAILED';

const initialState = {
  all: [],
  dialog: undefined,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_ROUTE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case SELECT_ROUTE:
      return {
        ...state,
        selected: state.all.find((route) => route._id.toString() === action.id),
      };
    case NEW_ROUTE:
      return {
        ...state,
        form: {
          isNew: true,
        },
      };
    case CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        selected: {
          ...state.selected,
          ...action.data,
        },
        form: undefined,
      };
    case CREATE_ROUTE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_ROUTE:
      return {
        ...state,
        form: state.all.find((x) => x._id.toString() === action.routeId),
      };
    case SAVE_ROUTE_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        selected: {
          ...state.selected,
          ...action.data,
        },
        form: undefined,
      };
    case SAVE_ROUTE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        all: state.all.filter((x) => x._id !== action.data._id),
        selected: undefined,
        form: undefined,
      };
    case DELETE_ROUTE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_ROUTE_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case CLOSE_ROUTE_FORM:
      return {
        ...state,
        form: undefined,
      };
    case CLEAR_SELECTED_ROUTE:
      return {
        ...state,
        selected: undefined,
      };
    case CLEAR_ROUTE_LIST:
      return {
        ...state,
        all: [],
      };
    case LOAD_ROUTE_SUCCESS:
      return {
        ...state,
        selected: action.data,
      };
    case LOAD_ROUTE_FAILED:
      return {
        ...state,
        selected: undefined,
      };
    case IMPORT_ROUTE_KML_FILE_SUCCESS:
      return {
        ...state,
        dialog: action.data,
      };
    case IMPORT_ROUTE_KML_FILE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLOSE_ROUTE_KML_DIALOG:
      return {
        ...state,
        dialog: undefined,
      };
    case SUBMIT_ROUTE_KML_FILE_SUCCESS:
      const newRoutes = action.data;
      const others = state.all.filter((x) => {
        for (const newRoute of newRoutes) {
          if (x._id.toString() === newRoute._id.toString()) {
            return false;
          }
        }
        return true;
      });

      return {
        ...state,
        all: [...others, ...newRoutes],
        selected: newRoutes[0],
        form: undefined,
        dialog: undefined,
      };
    case SUBMIT_ROUTE_KML_FILE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export default reducer;

export const loadRouteListSuccess = (data) => {
  return {
    type: LOAD_ROUTE_LIST_SUCCESS,
    data,
  };
};

export const loadRouteListFailed = (error) => {
  return {
    type: LOAD_ROUTE_LIST_FAILED,
    error,
  };
};

export const loadRouteList = (clientId, siteId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites/${siteId}/routes`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        const data = response.data.sort((a, b) => Collator.compare(a.name, b.name));
        dispatch(loadRouteListSuccess(data));
      })
      .catch((error) => {
        dispatch(loadRouteListFailed(error));
      });
  };
};

export const selectRoute = (id) => {
  return {
    type: SELECT_ROUTE,
    id,
  };
};

export const newRoute = () => {
  return {
    type: NEW_ROUTE,
  };
};

export const createRouteSuccess = (data) => {
  notification['success']({
    message: i18next.t('route:control.notification.create_success_message.message'),
    description: i18next.t('route:control.notification.create_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_ROUTE_SUCCESS,
    data,
  };
};

export const createRouteFailed = (error) => {
  notification['error']({
    message: i18next.t('route:control.notification.create_failed_message.message'),
    description: i18next.t('route:control.notification.create_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_ROUTE_FAILED,
    error,
  };
};

export const createRouteFailedOnDuplicatedData = (error) => {
  notification['error']({
    message: i18next.t('route:control.notification.create_failed_on_duplicated_code_field_message.message'),
    description: i18next.t('route:control.notification.create_failed_on_duplicated_code_field_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_ROUTE_FAILED,
    error,
  };
};

export const createRoute = (clientId, siteId, data) => {
  return (dispatch) => {
    axios
      .post(`/clients/${clientId}/sites/${siteId}/routes`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(createRouteSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 462) {
          dispatch(createRouteFailedOnDuplicatedData(error));
        } else {
          dispatch(createRouteFailed(error));
        }
      });
  };
};

export const updateRouteFormProperty = (data) => {
  return {
    type: UPDATE_ROUTE_PROPERTY,
    data,
  };
};

export const editRoute = (routeId) => {
  return {
    type: EDIT_ROUTE,
    routeId,
  };
};

export const deleteRoute = (routeId, clientId, siteId) => {
  return (dispatch) => {
    axios
      .delete(`/clients/${clientId}/sites/${siteId}/routes/${routeId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(deleteRouteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteRouteFailed(error));
      });
  };
};

export const deleteRouteSuccess = (data) => {
  notification['success']({
    message: i18next.t('route:control.notification.delete_success_message.message'),
    description: i18next.t('route:control.notification.delete_success_message.description'),
    className: 'sub2',
  });
  return {
    type: DELETE_ROUTE_SUCCESS,
    data,
  };
};

export const deleteRouteFailed = (error) => {
  notification['error']({
    message: i18next.t('route:control.notification.delete_failed_message.message'),
    description: i18next.t('route:control.notification.delete_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: DELETE_ROUTE_FAILED,
    error,
  };
};

export const saveRouteSuccess = (data) => {
  notification['success']({
    message: i18next.t('route:control.notification.save_success_message.message'),
    description: i18next.t('route:control.notification.save_success_message.description'),
    className: 'sub2',
  });
  return {
    type: SAVE_ROUTE_SUCCESS,
    data,
  };
};

export const saveRouteFailed = (error) => {
  notification['error']({
    message: i18next.t('route:control.notification.create_failed_message.message'),
    description: i18next.t('route:control.notification.create_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: SAVE_ROUTE_FAILED,
    error,
  };
};

export const saveRoute = (id, clientId, siteId, data) => {
  return (dispatch) => {
    axios
      .patch(`/clients/${clientId}/sites/${siteId}/routes/${id}`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(saveRouteSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 462) {
          createRouteFailedOnDuplicatedData(error);
        } else {
          dispatch(saveRouteFailed(error));
        }
      });
  };
};

export const closeRouteForm = () => {
  return {
    type: CLOSE_ROUTE_FORM,
  };
};

export const clearSelectedRoute = () => {
  return {
    type: CLEAR_SELECTED_ROUTE,
  };
};

export const clearRouteList = () => {
  return {
    type: CLEAR_ROUTE_LIST,
  };
};

export const loadRouteSuccess = (data) => {
  return {
    type: LOAD_ROUTE_SUCCESS,
    data,
  };
};

export const loadRouteFailed = (error) => {
  return {
    type: LOAD_ROUTE_FAILED,
    error,
  };
};

export const loadRoute = (clientId, siteId, routeId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites/${siteId}/routes/${routeId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadRouteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadRouteFailed(error));
      });
  };
};

export const importRouteKMLFile = (data) => {
  if (data === null) {
    notification['error']({
      message: i18next.t('route:control.notification.import_kml_unknown_failed_message.message'),
      description: i18next.t(
        'route:control.notification.import_kml_unknown_failed_message.description',
        'There is something wrong, please try again.'
      ),
    });
    return (dispatch) => {
      dispatch({
        type: IMPORT_ROUTE_KML_FILE_FAILED,
        error: 'Unable to read KML files.',
      });
    };
  }

  return (dispatch) => {
    dispatch({
      type: IMPORT_ROUTE_KML_FILE_SUCCESS,
      data,
    });
  };
};

export const submitRouteKMLFile = (clientId, siteId, data) => {
  if (data === null) {
    notification['error']({
      message: i18next.t('route:control.notification.import_kml_unknown_failed_message.message'),
      description: i18next.t('route:control.notification.import_kml_unknown_failed_message.description'),
    });
    return (dispatch) => {
      dispatch({
        type: SUBMIT_ROUTE_KML_FILE_FAILED,
        error: 'Unable to read KML files.',
      });
    };
  }

  return (dispatch) => {
    axios
      .post(`/clients/${clientId}/sites/${siteId}/routes/import`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        notification['success']({
          message: i18next.t('route:control.notification.import_kml_success_message.message'),
          description: i18next.t('route:control.notification.import_kml_success_message.description'),
        });
        dispatch({
          type: SUBMIT_ROUTE_KML_FILE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 462) {
          notification['error']({
            message: i18next.t('route:control.notification.import_kml_failed_message_on_duplicated_route_code.message'),
            description: i18next.t('route:control.notification.import_kml_failed_message_on_duplicated_route_code.description', {
              codes: error.response.data.data,
            }),
          });
        } else {
          notification['error']({
            message: i18next.t('route:control.notification.import_kml_unknown_failed_message.message'),
            description: i18next.t('route:control.notification.import_kml_unknown_failed_message.description'),
          });
        }

        dispatch({
          type: SUBMIT_ROUTE_KML_FILE_FAILED,
          error: error.response,
        });
      });
  };
};

export const closeRouteKMLdialog = () => {
  return {
    type: CLOSE_ROUTE_KML_DIALOG,
  };
};
