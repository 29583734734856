import React from 'react';
import styled from 'styled-components';

const MaterialIcon = styled.i.attrs({ className: 'material-icons' })`
  position: relative;
  /* Adjust these values accordingly */
  top: 4px;
  right: 4px;
  left: 2px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  :hover {
    ~ .tooltiptext {
      visibility: visible;
    }
  }
`;

const MandatatoryWrapper = styled.div`
  display: inline;
  position: relative;
  color: ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)};
  /* font-size: 16px; */
`;

const Wrapper = styled.div`
  display: flex;
  width: ${(props) => props.width};
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: ${(props) => (props.isError ? '0px' : '8px')};

  label {
    text-transform: uppercase;
    margin-bottom: 8px;
    color: ${(props) => (props.color in props.theme ? props.theme[props.color] : props.theme.primary)} !important;

    /* ${(props) => {
      if (props.color in props.theme) {
        return `${props.theme[props.color]} !important`;
      }
      return `${props.color} !important`;
    }}; */
  }

  span.fieldErrorMessage {
    /* error handle */
    margin-top: 8px;
    color: ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)};

    ~ label.fieldlabel {
      color: ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
    }

    ~ .trailingicon,
    ~ .leadingicon,
    ~ .erroricon {
      color: ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
      top: 40%;
    }

    ~ .trailingicon {
      display: none;
    }

    ~ .erroricon {
      display: inline;
    }

    ~ .form-control {
      border: 1px solid ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
      box-shadow: 0 0 0 1px ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
      :hover,
      :focus {
        ~ .trailingicon,
        ~ .leadingicon {
          color: ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
        }
      }
    }

    ~ * > .form-control {
      border: 1px solid ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
      box-shadow: 0 0 0 1px ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
      :hover,
      :focus {
        border: 1px solid ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
        box-shadow: 0 0 0 1px ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
      }
    }

    ~ * > .trailingicon {
      color: ${(props) => (props.errorColor in props.theme ? props.theme[props.errorColor] : props.theme.error)} !important;
    }
  }
`;

const SubField = (props) => {
  const { visible = true } = props;

  return visible ? (
    <Wrapper isError={props.errorMessage} width={props.width} color={props.color} errorColor={props.errorColor} className={'subField'}>
      {props.errorMessage && <span className='caption fieldErrorMessage'>{props.errorMessage}</span>}
      {props.children}
      <label className='overline fieldlabel'>
        {props.title} {props.isRequired && <MandatatoryWrapper errorColor={props.errorColor}>*</MandatatoryWrapper>}{' '}
        {props.infoText && <MaterialIcon title={props.infoText}>info</MaterialIcon>}
      </label>
    </Wrapper>
  ) : null;
};

export default SubField;
