import React from 'react';
import { Result } from 'antd';
import { Button } from './ui'
import useIntl from '../hooks/useIntl';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  const { t } = useIntl('notfound');

  return (
    <Result
      status='404'
      title='404'
      subTitle={t('notfound:not_found.title', 'Sorry, the page you visited does not exist.')}
      extra={
        <NavLink to="/">
          <Button>{t('notfound:not_found.button', 'Home')}</Button>
        </NavLink>
      }
    />
  )
};

export default NotFound;