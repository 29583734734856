import axios from '../axios';

// Actions
export const LOAD_ORGANIZATION_SETTING_SUCCESS = 'LOAD_ORGANIZATION_SETTING_SUCCESS';
export const LOAD_ORGANIZATION_SETTING_FAILED = 'LOAD_ORGANIZATION_SETTING_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_ORGANIZATION_SETTING_SUCCESS:
      return {
        ...state,
        info: {
          ...action.data,
        },
      };
    case LOAD_ORGANIZATION_SETTING_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const loadOrganizationSettingSuccess = (data) => {
  return {
    type: LOAD_ORGANIZATION_SETTING_SUCCESS,
    data,
  };
};

export const loadOrganizationSettingFailed = (error) => {
  return {
    type: LOAD_ORGANIZATION_SETTING_FAILED,
    error,
  };
};

// Action Creators
export const loadOrganizationSetting = (organizationId) => {
  return (dispatch) => {
    axios
      .get(`/organizations/${organizationId}/organizationsettings`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadOrganizationSettingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadOrganizationSettingFailed(error));
      });
  };
};
