import axios from '../axios';

export const LOAD_FLIGHT_EVENTS_SUCCESS = 'LOAD_FLIGHT_EVENTS_SUCCESS';
export const LOAD_FLIGHT_EVENTS_FAILED = 'LOAD_FLIGHT_EVENTS_FAILED';

export const CLEAR_SELECTED_FLIGHT_EVENTS = 'CLEAR_SELECTED_FLIGHT_EVENTS';

const initialState = {
  selected: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FLIGHT_EVENTS_SUCCESS:
      return {
        ...state,
        selected: action.data,
      };
    case LOAD_FLIGHT_EVENTS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_SELECTED_FLIGHT_EVENTS:
      return {
        ...state,
        selected: undefined,
      };
    default:
      return state;
  }
}

export const loadFlightEventsSuccess = (data) => {
  return {
    type: LOAD_FLIGHT_EVENTS_SUCCESS,
    data,
  };
};

export const loadFlightEventsFailed = (error) => {
  return {
    type: LOAD_FLIGHT_EVENTS_FAILED,
    error,
  };
};

export const loadFlightEvents = (flightId) => {
  return (dispatch) => {
    axios
      .get(`/flights/${flightId}/flightevents?$sort[eventDateTime]=1`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadFlightEventsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadFlightEventsFailed(error));
      });
  };
};

export const clearSelectedFlightEvents = () => {
  return {
    type: CLEAR_SELECTED_FLIGHT_EVENTS,
  };
};
