import axios from '../axios';

export const LOAD_FLIGHT_LIST_SUCCESS = 'LOAD_FLIGHT_LIST_SUCCESS';
export const LOAD_FLIGHT_LIST_FAILED = 'LOAD_FLIGHT_LIST_FAILED';

export const LOAD_FLIGHT_SUCCESS = 'LOAD_FLIGHT_SUCCESS';
export const LOAD_FLIGHT_FAILED = 'LOAD_FLIGHT_FAILED';
export const LOAD_FLIGHT = 'LOAD_FLIGHT';

export const APPEND_FLIGHT = 'APPEND_FLIGHT';
export const APPEND_FLIGHT_LOG = 'APPEND_FLIGHT_LOG';

export const CLEAR_SELECTED_FLIGHT = 'CLEAR_SELECTED_FLIGHT';

const initialState = {
  all: [],
  selected: undefined,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FLIGHT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_FLIGHT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_FLIGHT:
      return {
        ...state,
        loading: true,
      };
    case LOAD_FLIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: action.data,
      };
    case LOAD_FLIGHT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case APPEND_FLIGHT:
      return {
        ...state,
        all: [...state.all, action.data],
      };
    case APPEND_FLIGHT_LOG:
      return {
        ...state,
        selected: {
          ...state.selected,
          logs: Array.isArray(action.data) ? [...state.selected.logs, ...action.data] : [...state.selected.logs, action.data],
        },
      };
    case CLEAR_SELECTED_FLIGHT:
      return {
        ...state,
        loading: false,
        selected: undefined,
      };
    default:
      return state;
  }
}

export const loadFlightListSuccess = (data) => {
  return {
    type: LOAD_FLIGHT_LIST_SUCCESS,
    data,
  };
};

export const loadFlightListFailed = (error) => {
  return {
    type: LOAD_FLIGHT_LIST_FAILED,
    error,
  };
};

export const loadFlightList = (taskId) => {
  return (dispatch) => {
    axios
      .get(`/tasks/${taskId}/flights?$sort[startedAt]=1`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadFlightListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadFlightListFailed(error));
      });
  };
};

export const loadFlightSuccess = (data) => {
  return {
    type: LOAD_FLIGHT_SUCCESS,
    data,
  };
};

export const loadFlightFailed = (error) => {
  return {
    type: LOAD_FLIGHT_FAILED,
    error,
  };
};

export const loadFlight = (flightId) => {
  return (dispatch) => {
    axios
      .get(`/flights/${flightId}?includeFields=logs`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadFlightSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadFlightFailed(error));
      });
  };
};

export const appendFlight = (data) => {
  return {
    type: APPEND_FLIGHT,
    data,
  };
};

export const appendFlightLog = (data) => {
  return {
    type: APPEND_FLIGHT_LOG,
    data,
  };
};

export const clearSelectedFlight = () => {
  return {
    type: CLEAR_SELECTED_FLIGHT,
  };
};
