import React from 'react';
import styled from 'styled-components';
import useIntl from '../../../hooks/useIntl';
import spinnerMediumLight from  '../../../assets/images/spinner/spinner-lightbg-m.gif';
import spinnerLargeLight from  '../../../assets/images/spinner/spinner-lightbg-l.gif';
import spinnerMediumDark from  '../../../assets/images/spinner/spinner-darkbg-m.gif';
import spinnerLargeDark from  '../../../assets/images/spinner/spinner-darkbg-l.gif';

const spinnerMHalfSize = 36;
const spinnerLHalfSize = 64;
export const Mode = {
	Light: 'light',
	Dark: 'dark'
};
export const Size = {
	Medium: 'medium',
	Large: 'large'
};

const SpinnerWrapper = styled.div`
	position: relative;
	pointer-events: ${props => props.isLoading? 'auto' : 'none'};
    
  * {
    pointer-events: auto;
  }
`;

const SpinnerImgWrapper = styled.div`
  display: ${props => props.isLoading? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: calc(50% - ${props => props.spinnerSize}px);
  left: calc(50% - ${props => props.spinnerSize}px);

  z-index: 10;
`;

const SpinnerOverlay = styled.div`
  display: ${props => props.isLoading? 'flex' : 'none'};
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.mode === Mode.Dark? props.theme.overlay_dark_bg : props.theme.overlay_light_bg};
`;

const LoadingText = styled.span.attrs({ className: 'sub2'})`
  color: ${props => props.theme.white_text_medium};
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  text-transform: uppercase;
  font-size: 28px;
`;

const Spinner = props => {
  const { size = Size.Medium, isLoading = false, overlay = true, mode = Mode.Light } = props;
  const { t } = useIntl('ui');
  let src, spinnerSize;
  switch (size) {
    case Size.Medium:
			if (mode === Mode.Light) {
				src = spinnerMediumLight;
			} else if (mode === Mode.Dark) {
				src = spinnerMediumDark;
			}
      spinnerSize = spinnerMHalfSize;
      break;
    case Size.Large:
			if (mode === Mode.Light) {
				src = spinnerLargeLight;
			} else if (mode === Mode.Dark) {
				src = spinnerLargeDark;
			}
      spinnerSize = spinnerLHalfSize;
      break;
    default:
      src = spinnerMediumLight;
      spinnerSize = spinnerMHalfSize;
      break;
  }

  return (
    <SpinnerWrapper {...props}>
      { props.children }
      <SpinnerImgWrapper spinnerSize={spinnerSize} isLoading={isLoading}>
        <img src={src} alt='Spinner'/>
			  { size === Size.Large && <LoadingText>{t('ui:feedback.spinner.loading', 'Loading...')}</LoadingText> }
      </SpinnerImgWrapper>
      { overlay &&
        <SpinnerOverlay mode={mode} isLoading={isLoading} />
      }
    </SpinnerWrapper>
  );
};

export default Spinner;