import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_SOCKET_IO_ENDPOINT || 'http://localhost:3030', { path: '/socket.io' });

socket.on('reconnect', () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    console.log('attemp to login after socket is reconnected');
    socket.emit('create', 'auth/local', {
      strategy: 'jwt',
      accessToken: localStorage.getItem('accessToken'),
    });
  }
});

export default socket;
