import axios from '../axios';

export const START_REGISTRATION = 'START_REGISTRATION';
export const REGISTRATION_UPDATE_ORGANIZATION_PROPERTY = 'REGISTRATION_UPDATE_ORGANIZATION_PROPERTY';
export const REGISTRATION_UPDATE_ADMIN_ACCOUNT_PROPERTY = 'REGISTRATION_UPDATE_ADMIN_ACCOUNT_PROPERTY';

export const REGISTRATION_GO_TO_ADMIN_ACCOUNT_PAGE = 'REGISTRATION_GO_TO_ADMIN_ACCOUNT_PAGE';
export const REGISTRATION_GO_TO_CONFIRMATION_PAGE = 'REGISTRATION_GO_TO_CONFIRMATION_PAGE';
export const REGISTRATION_GO_TO_TO_POLICY_PAGE = 'REGISTRATION_GO_TO_TO_POLICY_PAGE';
export const REGISTRATION_GO_TO_NEXT_STEP_PAGE = 'REGISTRATION_GO_TO_NEXT_STEP_PAGE';

export const REGISTRATION_PENDING = 'REGISTRATION_PENDING';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';

export const VERIFY_REGISTRATION_SUCCESS = 'VERIFY_REGISTRATION_SUCCESS';
export const VERIFY_REGISTRATION_FAILED = 'VERIFY_REGISTRATION_FAILED';

export const VALIDATE_REGISTRATION_INFO_SUCCESS = 'VALIDATE_REGISTRATION_INFO_SUCCESS';
export const VALIDATE_REGISTRATION_INFO_FAILED = 'VALIDATE_REGISTRATION_INFO_FAILED';
export const VALIDATE_REGISTRATION_INFO_RESET = 'VALIDATE_REGISTRATION_INFO_RESET';

const apiKey = process.env.REACT_APP_INTERNAL_API_KEY || 'internal-api-key';
const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case START_REGISTRATION:
      return {
        organization: {},
        adminUser: {},
        loading: false,
        currentPage: 0,
        validateRegistrationResult: null,
      };
    case REGISTRATION_UPDATE_ORGANIZATION_PROPERTY:
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.data,
        },
      };
    case REGISTRATION_UPDATE_ADMIN_ACCOUNT_PROPERTY:
      return {
        ...state,
        adminUser: {
          ...state.adminUser,
          ...action.data,
        },
      };
    case REGISTRATION_PENDING:
      return {
        ...state,
        loading: true,
      };
    case REGISTRATION_FAILED:
      return {
        ...state,
        error: {
          message: action.error.message,
          code: action.error.data.errorCode,
        },
      };
    case REGISTRATION_GO_TO_NEXT_STEP_PAGE:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case VERIFY_REGISTRATION_SUCCESS:
      return {
        ...state,
        isVerifySuccess: true,
        isIndividualUser: action?.data?.organization?.isIndividualUser,
      };
    case VERIFY_REGISTRATION_FAILED:
      return {
        ...state,
        isVerifySuccess: false,
        error: {
          message: action.error.message,
        },
      };

    case VALIDATE_REGISTRATION_INFO_SUCCESS:
      return {
        ...state,
        validateRegistrationResult: {
          isValidateSuccess: true,
          errorField: {},
        },
      };

    case VALIDATE_REGISTRATION_INFO_FAILED:
      return {
        ...state,
        validateRegistrationResult: {
          isValidateSuccess: false,
          errorField: action.errorField,
        },
      };

    case VALIDATE_REGISTRATION_INFO_RESET:
      return {
        ...state,
        validateRegistrationResult: {
          isValidateSuccess: undefined,
          errorField: {},
        },
      };

    default:
      return state;
  }
}

export const startRegistration = (data) => {
  return {
    type: START_REGISTRATION,
    data,
  };
};

export const updateOrganizationProperty = (data) => {
  return {
    type: REGISTRATION_UPDATE_ORGANIZATION_PROPERTY,
    data,
  };
};

export const updateAdminAccountProperty = (data) => {
  return {
    type: REGISTRATION_UPDATE_ADMIN_ACCOUNT_PROPERTY,
    data,
  };
};

export const registrationPending = () => {
  return {
    type: REGISTRATION_PENDING,
  };
};

export const registrationSuccess = () => {
  return {
    type: REGISTRATION_SUCCESS,
  };
};

export const registrationFailed = (error) => {
  return {
    type: REGISTRATION_FAILED,
    error,
  };
};

export const submitRegistration = (data) => {
  return (dispatch) => {
    dispatch(registrationPending());
    axios
      .post(`/registration`, data, { headers: { Authorization: apiKey, internal: apiKey } })
      .then(() => {
        dispatch(goToNextStep());
      })
      .catch((error) => {
        dispatch(registrationFailed(error.response.data));
      });
  };
};

export const goToNextStep = () => {
  return {
    type: REGISTRATION_GO_TO_NEXT_STEP_PAGE,
  };
};

export const verifyRegistrationSuccess = (data) => {
  return {
    type: VERIFY_REGISTRATION_SUCCESS,
    data,
  };
};

export const verifyRegistrationFailed = (error) => {
  return {
    type: VERIFY_REGISTRATION_FAILED,
    error,
  };
};

export const verifyRegistration = (registrationKey) => {
  return (dispatch) => {
    axios
      .post(`/confirm-registration?key=${registrationKey}`, {}, { headers: { Authorization: apiKey, internal: apiKey } })
      .then((response) => {
        dispatch(verifyRegistrationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(verifyRegistrationFailed(error.response.data));
      });
  };
};

export const validateRegistrationInfoFailed = (errorField) => {
  return { type: VALIDATE_REGISTRATION_INFO_FAILED, errorField: errorField };
};

export const validateRegistrationInfoSuccess = () => {
  return { type: VALIDATE_REGISTRATION_INFO_SUCCESS };
};

export const EMAIL_FORMAT_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
export const validateRegistrationInfo = (adminUser) => {
  const errorField = {};

  // TODO: rework valiadtion on others fields
  if (!adminUser.name || adminUser.name.length <= 0) {
    errorField.name = 'Please enter admin name';
  }
  if (!adminUser.email || adminUser.email.length <= 0) {
    errorField.email = 'Please enter admin email';
  }
  if (adminUser.email && !EMAIL_FORMAT_REGEX.test(adminUser.email)) {
    errorField.email = 'Email address is invalid.';
  }
  if (!adminUser.password || adminUser.password.length <= 0) {
    errorField.password = 'Please enter password';
  }
  if (!adminUser.confirmPassword || adminUser.confirmPassword.length <= 0) {
    errorField.confirmPassword = 'Please confirm password';
  } else if (adminUser.password !== adminUser.confirmPassword) {
    errorField.confirmPassword = 'Password mismatch';
  }

  if (Object.keys(errorField).length > 0) {
    return (dispatch) => {
      dispatch({
        type: VALIDATE_REGISTRATION_INFO_FAILED,
        errorField,
      });
    };
  } else {
    return (dispatch) => {
      // reset validate
      dispatch({ type: VALIDATE_REGISTRATION_INFO_RESET });

      axios.post(`/registration/validate`, { adminUser }, { headers: { Authorization: apiKey, internal: apiKey } }).then((res) => {
        if (!res.data.isValidateSuccess) {
          dispatch(validateRegistrationInfoFailed(res.data.errorField));
        } else {
          dispatch(validateRegistrationInfoSuccess());
        }
      });
    };
  }
};
