import React from 'react'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// Breadcrumb Item
const BreadcrumbItemWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    color: ${props => props.current ? props.theme.primary : props.theme.gray_700};
  }
`;
const H5 = styled.h6`
  margin: 0;
  display: inline-flex;
  line-height: 28px;
  color: ${props => props.current ? props.theme.primary : props.theme.gray_700};    
`;

export const BreadcrumbItem = props => {
  const { children, current, logo } = props;
  let link
  if (props.link) {
    link = props.link;
  } else {
    link = '/404';
  }
  return (
    <BreadcrumbItemWrapper>
      <H5 logo={logo} current={current}>
        {current ? children : <NavLink to={link}>{children}</NavLink>}
      </H5>
    </BreadcrumbItemWrapper>
  );
};

export default BreadcrumbItem;