import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &:last-child {
    flex: 0;
    .line {
      display: none;
    }
  }

  * {
    color: ${props => {
      if (props.currentOrder === props.order) {
        return `${props.theme.secondary}`;
      }
      else if (props.currentOrder > props.order) {
        return `${props.theme.primary}`;
      }
      else {
        return `${props.theme.gray_500}`;
      }
    }}
  }
`

const Order = styled.div.attrs({ className: 'headline5' })`
  display: flex;
  span {
    display: block;
  }
  .line {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    hr {
      width: 100%;
      border: none;
      height: 1px;
      background-color: ${props => props.theme.gray_500};
    }
  }
`

const Title = styled.span.attrs({ className: 'sub1' })``

export default ({ title, order, currentOrder }) => (
  <Wrapper currentOrder={currentOrder} order={order}>
    <Order>
      <span>
        {`${order + 1}`.padStart(2, '0')}
      </span>
      <div className="line">
        <hr />
      </div>
    </Order>
    <Title>{title}</Title>
  </Wrapper>
);