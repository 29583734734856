import React from 'react';
import styled from 'styled-components';
import defaultImg from '../../../../../assets/images/hg_mark-one_color_tonal_on_dark.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  user-select: none;

  .sub1::after {
    content: '.';
    visibility: hidden;
  }

  .body2::after {
    content: '.';
    visibility: hidden;
  }
`

const Img = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 8px;

`

export default ({ data }) => (
  <Wrapper>
    <Img src={data.img || defaultImg} />
    <div>
      <div className="sub1">
        {data.text}
      </div>
      <div className="body2">
        {data.subtext}
      </div>
    </div>
  </Wrapper>
);