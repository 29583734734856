import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import withIntl from '../../../hoc/withIntl';
import Prompt from './Prompt';
import { isEqual } from 'lodash';

import { init, deinit, updateObservable } from '../../../redux/prompt';

const dirtyState = {
  UNKNOWN: 'UNKNOWN',
  DIRTY: 'DIRTY',
  CLEAN: 'CLEAN',
};

class ChangeTrackingPrompt extends Component {
  constructor(props) {
    super(props);
    const state = {
      committed: null,
      dirty: dirtyState.UNKNOWN,
    };
    this.state = state;
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, observable, committed } = this.props;
    if (data && !prevProps.data) {
      this.props.init(data);
    }

    // tracking data has been updated
    const hasChanges = data && !isEqual(prevProps.data, data);
    if (hasChanges && !committed) {
      this.props.update(data);
      this.setState({ dirty: dirtyState.DIRTY, committed: null });
    }

    // observable object has been destroy which means data changes has been committed
    if (!observable && committed && prevProps.observable) {
      // lower dirty flag
      this.setState({ dirty: dirtyState.CLEAN, committed });
    }

    // lower dirty flag successfully
    if (this.state && this.state.dirty === dirtyState.CLEAN && prevState.dirty === dirtyState.DIRTY) {
      const { onChangeComitted } = this.props;
      const { committed } = this.state;
      if (onChangeComitted) {
        onChangeComitted(committed);
      }
    }
  }

  componentWillUnmount() {
    this.props.deinit();
  }

  render() {
    const { t, data } = this.props;
    if (!data) {
      return null;
    }

    const { dirty } = this.state;
    const isDirty = dirty === dirtyState.DIRTY;

    return isDirty ? (
      <Prompt
        title={t('ui:dialog.unsaved_changes.title')}
        message={t('ui:dialog.unsaved_changes.message')}
        when={isDirty}
        okText={t('ui:dialog.unsaved_changes.okText')}
        cancelText={t('ui:dialog.unsaved_changes.cancelText')}
        onOk={() => false}
        onCancel={() => true}></Prompt>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    observable: state.prompt.observable,
    committed: state.prompt.committed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: (data) => dispatch(init(data)),
    update: (data) => dispatch(updateObservable(data)),
    deinit: () => dispatch(deinit()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withIntl('ui'))(ChangeTrackingPrompt);
