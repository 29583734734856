import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_VEHICLE_LIST_SUCCESS = 'LOAD_VEHICLE_LIST_SUCCESS';
export const LOAD_VEHICLE_LIST_FAILED = 'LOAD_VEHICLE_LIST_FAILED';
export const LOAD_VEHICLE_DETAIL_SUCCESS = 'LOAD_VEHICLE_DETAIL_SUCCESS';
export const LOAD_VEHICLE_DETAIL_FAILED = 'LOAD_VEHICLE_DETAIL_FAILED';
export const CLEAR_VEHICLE_STATS_DETAIL = 'CLEAR_VEHICLE_STATS_DETAIL';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_FAILED = 'CREATE_VEHICLE_FAILED';
export const SAVE_VEHICLE_SUCCESS = 'SAVE_VEHICLE_SUCCESS';
export const SAVE_VEHICLE_FAILED = 'SAVE_VEHICLE_FAILED';

const initialState = {
  all: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_VEHICLE_DETAIL_SUCCESS:
      return {
        ...state,
        info: action.data,
      };
    case LOAD_VEHICLE_DETAIL_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_VEHICLE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case SAVE_VEHICLE_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
      };
    case SAVE_VEHICLE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
      };
    case CREATE_VEHICLE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_VEHICLE_STATS_DETAIL:
      return {
        ...state,
        status: undefined,
        info: undefined,
        error: undefined,
      };

    default:
      return state;
  }
}

// Action Creators
export const loadVehicleListSuccess = (data) => {
  return {
    type: LOAD_VEHICLE_LIST_SUCCESS,
    data,
  };
};
export const loadVehicleListFailed = (error) => {
  return {
    type: LOAD_VEHICLE_LIST_FAILED,
    error,
  };
};
export const loadVehicleDetailSuccess = (data) => {
  return {
    type: LOAD_VEHICLE_DETAIL_SUCCESS,
    data,
  };
};
export const loadVehicleDetailFailed = (error) => {
  return {
    type: LOAD_VEHICLE_DETAIL_FAILED,
    error,
  };
};
export const clearVehicleStatDetail = () => {
  return {
    type: CLEAR_VEHICLE_STATS_DETAIL,
  };
};

export const createVehicleSuccess = (data) => {
  notification['success']({
    message: i18next.t('vehicle:control.notification.create_success_message.message'),
    description: i18next.t('vehicle:control.notification.create_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_VEHICLE_SUCCESS,
    data,
  };
};

export const createVehicleFailed = (error) => {
  if (error.code === 409) {
    notification['error']({
      message: i18next.t('vehicle:control.notification.save_failed_duplicate_serial_message.message'),
      description: i18next.t('vehicle:control.notification.save_failed_duplicate_serial_message.description'),
      className: 'sub2',
    });
  } else {
    notification['error']({
      message: i18next.t('vehicle:control.notification.create_failed_message.message'),
      description: i18next.t('vehicle:control.notification.create_failed_message.description'),
      className: 'sub2',
    });
  }
  return {
    type: CREATE_VEHICLE_FAILED,
    error,
  };
};

export const saveVehicleSuccess = (data) => {
  notification['success']({
    message: i18next.t('vehicle:control.notification.save_success_message.message'),
    description: i18next.t('vehicle:control.notification.save_success_message.description'),
    className: 'sub2',
  });
  return {
    type: SAVE_VEHICLE_SUCCESS,
    data,
  };
};

export const saveVehicleFailed = (error) => {
  if (error.code === 409) {
    notification['error']({
      message: i18next.t('vehicle:control.notification.save_failed_duplicate_serial_message.message'),
      description: i18next.t('vehicle:control.notification.save_failed_duplicate_serial_message.description'),
      className: 'sub2',
    });
  } else {
    notification['error']({
      message: i18next.t('vehicle:control.notification.save_failed_message.message'),
      description: i18next.t('vehicle:control.notification.save_failed_message.description'),
      className: 'sub2',
    });
  }
  return {
    type: SAVE_VEHICLE_FAILED,
    error,
  };
};

export const loadVehicleList = () => {
  return (dispatch) => {
    axios
      .get('/vehicles?includeFields=vehicleModel', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadVehicleListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadVehicleListFailed(error));
      });
  };
};

export const loadVehicleDetail = (vehicleId) => {
  return (dispatch) => {
    axios
      .get(`/vehicles/${vehicleId}?includeFields=vehicleModel`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadVehicleDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadVehicleDetailFailed(error));
      });
  };
};

export const createVehicle = (history, data) => {
  return (dispatch) => {
    axios
      .post('/vehicles/', data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createVehicleSuccess(response.data));
        history.replace('.');
      })
      .catch((error) => {
        dispatch(createVehicleFailed(error.response.data));
      });
  };
};

export const saveVehicle = (history, id, data) => {
  return (dispatch) => {
    axios
      .patch(`/vehicles/${id}`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveVehicleSuccess(response.data));
        history.replace('.');
      })
      .catch((error) => {
        dispatch(saveVehicleFailed(error.response.data));
      });
  };
};
