import React, { Component } from 'react';
import styled from 'styled-components';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableContext from './TableContext';

export const SortDirection = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.border_line};
  border-radius: 4px;
  background-color: ${(props) => props.theme.light};
  padding: 0 16px;
  overflow: auto;

  table {
    width: 100%;
    thead th {
      background-color: ${(props) => props.theme.light};
      position: sticky;
      top: 0;
      & > div {
        border-bottom: 1px solid ${(props) => props.theme.border_line};
      }
    }
    tbody > tr:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.border_line};
    }
    th,
    td {
      min-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const LoadingText = styled.span.attrs({ className: 'sub2' })`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const lazyloadDelay = 500;
const scrollError = 5;
const tableRowHeight = 54;
class Table extends Component {
  constructor(props) {
    super(props);

    this.sortData = (columnTitle, sorter) => {
      if (!sorter) return;
      this.setState((state) => {
        const { sorted, reversed } = state;
        if (sorted && sorted === columnTitle)
          return {
            sorted: undefined,
            reversed: columnTitle,
            dataSource: state.dataSource.reverse(sorter),
          };
        else if (reversed && reversed === columnTitle) {
          return {
            sorted: columnTitle,
            reversed: undefined,
            dataSource: state.dataSource.sort(sorter),
          };
        }
        return {
          sorted: columnTitle,
          reversed: undefined,
          dataSource: state.dataSource.sort(sorter),
        };
      });
    };

    this.state = {
      tableLoading: false,
      dataSource: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleTableResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleTableResize);
  }

  handleTableResize = () => {
    const { infiniteScroll = true } = this.props;
    if (infiniteScroll) {
      this.calculateTableHeight();
      const { itemCount, itemPerPage } = this.state;
      this.setState({ itemCount: itemCount > itemPerPage ? itemCount : itemPerPage });
    }
  };

  async componentDidUpdate(props) {
    const { dataSource, infiniteScroll = true, sortingColumn, direction = SortDirection.ASCENDING } = props;
    if (this.state.dataSource !== dataSource) {
      if (sortingColumn) {
        const { headerTitle, sorter } = props.columns.find((x) => x.key === sortingColumn);
        if (!sorter) {
          return;
        }
        if (direction === SortDirection.ASCENDING) {
          this.setState({
            sorted: headerTitle,
            reversed: undefined,
            dataSource: this.state.dataSource.sort(sorter),
          });
        } else if (direction === SortDirection.DESCENDING) {
          const sortedData = this.state.dataSource.sort(sorter);
          this.setState({
            sorted: undefined,
            reversed: headerTitle,
            dataSource: sortedData.reverse(sorter),
          });
        }
      }

      if (infiniteScroll) {
        this.calculateTableHeight();
        const { itemPerPage } = this.state;
        this.setState({ itemCount: itemPerPage });
        this._table.scrollTop = 0;
      }
    }
  }

  calculateTableHeight = () => {
    const { mode } = this.props;
    const bottomMargin = 24;
    const tableHeaderHeight = 40;
    const availableSpace = Math.max(
      this._table.getBoundingClientRect().height,
      document.documentElement.offsetHeight - this._table.getBoundingClientRect().top - bottomMargin
    );
    const itemPerPage = (availableSpace - tableHeaderHeight) / tableRowHeight;
    const tableHeight = mode === 'Custom' ? itemPerPage * tableRowHeight : itemPerPage * tableRowHeight + tableHeaderHeight;

    this.setState(() => {
      return {
        tableHeight,
        itemPerPage: Math.floor(itemPerPage),
        tableLoading: false,
      };
    });
  };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      dataSource: props.dataSource,
    };
  }

  static defaultProps() {
    return {
      onRowAction: () => ({}),
    };
  }

  lazyLoad = async (event) => {
    const target = event.target;
    const { dataSource, tableLoading, itemCount, itemPerPage } = this.state;

    if (
      target.scrollHeight - target.scrollTop - tableRowHeight / 3 - scrollError <= target.clientHeight &&
      itemCount < dataSource.length &&
      !tableLoading
    ) {
      this.setState({ tableLoading: true });
      setTimeout(() => {
        this.setState({ itemCount: itemCount + itemPerPage });
        this.setState({ tableLoading: false });
      }, lazyloadDelay);
    }
  };

  modifiedData = () => {
    const { dataSource, itemCount } = this.state;
    const { infiniteScroll = true } = this.props;
    if (infiniteScroll) {
      return dataSource.slice(0, itemCount);
    }
    return dataSource;
  };

  render() {
    const { columns, rowKey, onRowAction, actionButton, infiniteScroll = true, id, className } = this.props;
    const { dataSource, sorted, reversed, itemCount, tableHeight } = this.state;

    return (
      <Wrapper
        className={className}
        onScroll={infiniteScroll ? this.lazyLoad : null}
        innerRef={(x) => (this._table = x)}
        style={{ height: tableHeight }}>
        <table id={id} cellSpacing={0} cellPadding={0}>
          <TableContext.Provider value={{ sortData: this.sortData, sorted, reversed, actionButton }}>
            <thead>
              <TableHeader columns={columns} />
            </thead>
            <tbody>
              {this.modifiedData().map((data) => (
                <TableRow {...onRowAction(data)} key={data[rowKey]} data={data} columns={columns} />
              ))}
              {infiniteScroll && itemCount < dataSource.length && (
                <tr>
                  <td colSpan={columns.length}>
                    <LoadingText>Loading...</LoadingText>
                  </td>
                </tr>
              )}
            </tbody>
          </TableContext.Provider>
        </table>
      </Wrapper>
    );
  }
}

Table.defaultProps = { onRowAction: () => ({}) };
export default Table;
