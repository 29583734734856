import { CREATE_TASK_SUCCESS } from './task';

export const INIT_OBSERVABLE = 'INIT_OBSERVABLE';
export const UPDATE_OBSERVABLE = 'UPDATE_OBSERVABLE';
export const DEINIT_OBSERVABLE = 'DEINIT_OBSERVABLE';

const initialState = {
  observable: null,
  committed: null,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_OBSERVABLE:
      return {
        ...state,
        observable: action.data,
      };

    case UPDATE_OBSERVABLE:
      return {
        ...state,
        observable: action.data,
      };

    case DEINIT_OBSERVABLE:
      return {
        ...state,
        observable: null,
        committed: null,
      };

    // add more action types here when using ChangeTrackingPromt on the other components
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        observable: null,
        committed: action.data,
      };

    default:
      return state;
  }
}

export const init = (data) => {
  return {
    type: INIT_OBSERVABLE,
    data,
  };
};

export const updateObservable = (data) => {
  return {
    type: UPDATE_OBSERVABLE,
    data,
  };
};

export const deinit = () => {
  return {
    type: DEINIT_OBSERVABLE,
  };
};
