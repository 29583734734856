import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';
import Collator from '../collator';

// Actions
export const LOAD_PLOT_LIST_SUCCESS = 'LOAD_PLOT_LIST_SUCCESS';
export const LOAD_PLOT_LIST_FAILED = 'LOAD_PLOT_LIST_FAILED';

export const SELECT_PLOT = 'SELECT_PLOT';
export const UPDATE_PLOT_PROPERTY = 'UPDATE_PLOT_PROPERTY';
export const CLOSE_PLOT_FORM = 'CLOSE_PLOT_FORM';

export const NEW_PLOT = 'NEW_PLOT';
export const CREATE_PLOT_SUCCESS = 'CREATE_PLOT_SUCCESS';
export const CREATE_PLOT_FAILED = 'CREATE_PLOT_FAILED';

export const EDIT_PLOT = 'EDIT_PLOT';
export const SAVE_PLOT_SUCCESS = 'SAVE_PLOT_SUCCCESS';
export const SAVE_PLOT_FAILED = 'SAVE_PLOT_FAILED';

export const CLEAR_SELECTED_PLOT = 'CLEAR_SELECTED_PLOT';
export const CLEAR_PLOT_LIST = 'CLEAR_PLOT_LIST';

export const IMPORT_PLOT_KML_FILE = 'IMPORT_PLOT_KML_FILE';
export const IMPORT_PLOT_KML_FILE_SUCCESS = 'IMPORT_PLOT_KML_FILE_SUCCESS';
export const IMPORT_PLOT_KML_FILE_FAILED = 'IMPORT_PLOT_KML_FILE_FAILED';
export const CLOSE_KML_DIALOG = 'CLOSE_KML_DIALOG';

export const SUBMIT_PLOT_KML_FILE = 'SUBMIT_PLOT_MKL_FILE';
export const SUBMIT_PLOT_KML_FILE_SUCCESS = 'SUBMIT_PLOT_MKL_FILE_SUCCESS';
export const SUBMIT_PLOT_KML_FILE_FAILED = 'SUBMIT_PLOT_MKL_FILE_FAILED';

export const LOAD_PLOT_SUCCESS = 'LOAD_PLOT_SUCCESS';
export const LOAD_PLOT_FAILED = 'LOAD_PLOT_FAILED';

export const LOAD_CLIENT_PLOT_LIST_SUCCESS = 'LOAD_CLIENT_PLOT_LIST_SUCCESS';
export const LOAD_CLIENT_PLOT_LIST_FAILED = 'LOAD_CLIENT_PLOT_LIST_FAILED';

const initialState = {
  all: [],
  dialog: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_PLOT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_PLOT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case SELECT_PLOT:
      return {
        ...state,
        selected: state.all.find((plot) => plot._id.toString() === action.id),
      };
    case NEW_PLOT:
      return {
        ...state,
        form: {
          isNew: true,
          name: `Plot ${state.all.length + 1}`,
        },
      };
    case CREATE_PLOT_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        selected: {
          ...state.selected,
          ...action.data,
        },
        form: undefined,
      };
    case CREATE_PLOT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case IMPORT_PLOT_KML_FILE_SUCCESS:
      return {
        ...state,
        dialog: action.data,
      };
    case IMPORT_PLOT_KML_FILE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLOSE_KML_DIALOG:
      return {
        ...state,
        dialog: undefined,
      };
    case SUBMIT_PLOT_KML_FILE_SUCCESS:
      const newPlots = action.data.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj._id).indexOf(obj._id) === pos;
      });

      return {
        ...state,
        all: [
          ...state.all.filter((x) => {
            for (const newPlot of newPlots) {
              if (x._id.toString() === newPlot._id.toString()) {
                return false;
              }
            }
            return true;
          }),
          ...newPlots,
        ],
        selected: {
          ...state.selected,
          ...action.data[0],
        },
        form: undefined,
        dialog: undefined,
      };
    case SUBMIT_PLOT_KML_FILE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_PLOT:
      return {
        ...state,
        form: state.all.find((x) => x._id.toString() === action.plotId),
      };
    case SAVE_PLOT_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        selected: {
          ...state.selected,
          ...action.data,
        },
        form: undefined,
      };
    case SAVE_PLOT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_PLOT_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case CLOSE_PLOT_FORM:
      return {
        ...state,
        form: undefined,
      };
    case CLEAR_SELECTED_PLOT:
      return {
        ...state,
        selected: undefined,
      };
    case CLEAR_PLOT_LIST:
      return {
        ...state,
        all: [],
      };
    case LOAD_PLOT_SUCCESS:
      return {
        ...state,
        selected: action.data,
      };
    case LOAD_PLOT_FAILED:
      return {
        ...state,
        selected: undefined,
      };
    case LOAD_CLIENT_PLOT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_CLIENT_PLOT_LIST_FAILED:
      return {
        ...state,
        all: [],
      };
    default:
      return state;
  }
}

export const loadPlotListSuccess = (data) => {
  return {
    type: LOAD_PLOT_LIST_SUCCESS,
    data,
  };
};

export const loadPlotListFailed = (error) => {
  return {
    type: LOAD_PLOT_LIST_FAILED,
    error,
  };
};

export const loadPlotList = (clientId, siteId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites/${siteId}/plots?includeFields=currentPlant`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        const data = response.data.sort((a, b) => Collator.compare(a.name, b.name));
        dispatch(loadPlotListSuccess(data));
      })
      .catch((error) => {
        dispatch(loadPlotListFailed(error));
      });
  };
};

export const selectPlot = (id) => {
  return {
    type: SELECT_PLOT,
    id,
  };
};

export const newPlot = () => {
  return {
    type: NEW_PLOT,
  };
};

export const createPlotSuccess = (data) => {
  notification['success']({
    message: i18next.t('plot:control.notification.create_success_message.message', 'Successful!'),
    description: i18next.t('plot:control.notification.create_success_message.description', 'New plot has been added.'),
    className: 'sub2',
  });
  return {
    type: CREATE_PLOT_SUCCESS,
    data,
  };
};

export const createPlotFailed = (error) => {
  notification['error']({
    message: i18next.t('plot:control.notification.create_failed_message.message', 'Error!'),
    description: i18next.t('plot:control.notification.create_failed_message.description', 'There is something wrong, please try again.'),
    className: 'sub2',
  });
  return {
    type: CREATE_PLOT_FAILED,
    error,
  };
};

export const createPlot = (clientId, siteId, data) => {
  return (dispatch) => {
    axios
      .post(`/clients/${clientId}/sites/${siteId}/plots`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(createPlotSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createPlotFailed(error));
      });
  };
};

export const importPlotKMLFileSuccess = (data) => {
  return {
    type: IMPORT_PLOT_KML_FILE_SUCCESS,
    data,
  };
};

export const importPlotKMLFileFailed = (error) => {
  notification['error']({
    message: i18next.t(`plot:control.notification.${error}.message`),
    description: i18next.t(`plot:control.notification.${error}.description`),
  });
  return {
    type: IMPORT_PLOT_KML_FILE_FAILED,
    error,
  };
};

export const importPlotKMLFile = (data, error) => {
  if (data === null) {
    return (dispatch) => {
      dispatch(importPlotKMLFileFailed(error));
    };
  }

  return (dispatch) => {
    dispatch(importPlotKMLFileSuccess(data));
  };
};

export const closeKMLdialog = () => {
  return {
    type: CLOSE_KML_DIALOG,
  };
};

export const submitPlotKMLFileSuccess = (data) => {
  notification['success']({
    message: i18next.t('plot:control.notification.import_kml_success_message.message', 'Imported!'),
    description: i18next.t('plot:control.notification.import_kml_success_message.description', 'KML file has been imported.'),
  });
  return {
    type: SUBMIT_PLOT_KML_FILE_SUCCESS,
    data,
  };
};

export const submitPlotKMLFileFailed = (error) => {
  notification['error']({
    message: i18next.t('plot:control.notification.import_kml_failed_message.message', 'Error!'),
    description: i18next.t(
      'plot:control.notification.import_kml_failed_message.description',
      'There is something wrong, please try again.'
    ),
  });
  return {
    type: SUBMIT_PLOT_KML_FILE_FAILED,
    error,
  };
};

export const submitPlotKMLFile = (clientId, siteId, data) => {
  if (data === null) {
    return (dispatch) => {
      dispatch(submitPlotKMLFileFailed('Unable to read KML files.'));
    };
  }
  return (dispatch) => {
    axios
      .post(`/clients/${clientId}/sites/${siteId}/plots/import`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(submitPlotKMLFileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(submitPlotKMLFileFailed(error.response));
      });
  };
};

export const updatePlotProperty = (data) => {
  return {
    type: UPDATE_PLOT_PROPERTY,
    data,
  };
};

export const editPlot = (plotId) => {
  return {
    type: EDIT_PLOT,
    plotId,
  };
};

export const savePlotSuccess = (data) => {
  notification['success']({
    message: i18next.t('plot:control.notification.save_success_message.message', 'Successful!'),
    description: i18next.t('plot:control.notification.save_success_message.description', 'The site has been edited.'),
    className: 'sub2',
  });
  return {
    type: SAVE_PLOT_SUCCESS,
    data,
  };
};

export const savePlotFailed = (error) => {
  notification['error']({
    message: i18next.t('plot:control.notification.create_failed_message.message', 'Error!'),
    description: i18next.t('plot:control.notification.create_failed_message.description', 'There is something wrong, please try again.'),
    className: 'sub2',
  });
  return {
    type: SAVE_PLOT_FAILED,
    error,
  };
};

export const savePlot = (id, clientId, siteId, data) => {
  return (dispatch) => {
    axios
      .patch(`/clients/${clientId}/sites/${siteId}/plots/${id}`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(savePlotSuccess(response.data));
      })
      .catch((error) => {
        dispatch(savePlotFailed(error));
      });
  };
};

export const closePlotForm = () => {
  return {
    type: CLOSE_PLOT_FORM,
  };
};

export const clearSelectedPlot = () => {
  return {
    type: CLEAR_SELECTED_PLOT,
  };
};

export const clearPlotList = () => {
  return {
    type: CLEAR_PLOT_LIST,
  };
};

export const loadPlotSuccess = (data) => {
  return {
    type: LOAD_PLOT_SUCCESS,
    data,
  };
};

export const loadPlotFailed = (error) => {
  return {
    type: LOAD_PLOT_FAILED,
    error,
  };
};

export const loadPlot = (clientId, siteId, plotId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites/${siteId}/plots/${plotId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadPlotSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPlotFailed(error));
      });
  };
};

export const loadClientPlotListSuccess = (data) => {
  return {
    type: LOAD_CLIENT_PLOT_LIST_SUCCESS,
    data,
  };
};

export const loadClientPlotListFailed = (error) => {
  notification['error']({
    message: i18next.t('plot:control.notification.load_client_plot_list.message', 'Error!'),
    description: i18next.t(
      'plot:control.notification.load_client_plot_list.description',
      'Unable to load plots for the selected client. Please try again.'
    ),
    className: 'sub2',
  });
  return {
    type: LOAD_CLIENT_PLOT_LIST_FAILED,
    error,
  };
};

export const loadClientPlotList = (clientId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/plots?includeFields=site`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadClientPlotListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadClientPlotListFailed(error));
      });
  };
};
