import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';
import { SingleLineText } from '../Select/preset';

const fixControlledValue = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return value;
};

const Input = styled.input`
  width: 100%;
  height: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: ${(props) => (props.leadingicon ? '44px' : '12px')};
  padding-right: ${(props) => (props.trailingicon ? '36px' : '12px')};
  border-radius: 4px;
  line-height: 24px;
  border: 1px solid ${(props) => props.theme.border_text_field};
  box-shadow: 0 0 0 1px transparent;
  background-color: ${(props) => props.theme.light};

  &::placeholder {
    color: ${(props) => props.theme.gray_500};
  }

  &:hover:not(:disabled):not(:focus) {
    border: 1px solid ${(props) => props.theme.primary_800};
    ~ .trailingicon,
    ~ .leadingicon,
    ~ label.fieldlabel {
      color: ${(props) => props.theme.primary_800};
    }
  }

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.secondary_400};
    box-shadow: 0 0 0 1px ${(props) => props.theme.secondary_400};
    ~ .trailingicon,
    ~ .leadingicon,
    ~ label.fieldlabel {
      color: ${(props) => props.theme.secondary_400};
    }
  }

  &:disabled {
    background-color: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.text_on_surface_disabled};

    ~ label.fieldlabel {
      color: ${(props) => props.theme.text_on_surface_disabled};
    }
  }

  ~ .trailingicon {
    position: absolute;
    top: 50%;
    right: 12px;
  }

  ~ .leadingicon {
    position: absolute;
    top: 50%;
    left: 12px;
  }

  ~ .erroricon {
    position: absolute;
    top: 40%;
    right: 12px;
    display: none;
  }

  ~ label.fieldlabel {
    color: ${(props) => props.theme.gray_700};
  }
`;

// Selector
const DropDownWrapper = styled.div`
  max-height: 368px;
  width: 100%;
  background-color: ${(props) => props.theme.light};
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px;
  z-index: 1;
  cursor: default;
  user-select: none;
  position: absolute;
  top: 85px;

  .select-body {
    max-height: 246px;
    min-width: 300px;
    overflow-y: auto;

    .select-option {
      padding-left: 12px;
      margin-right: 12px;
      margin-left: 12px;
      min-height: 48px;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1px solid ${(props) => props.theme.gray_300};

      :hover {
        background-color: ${(props) => props.theme.primary_50};
      }

      i.checked {
        position: absolute;
        right: 12px;
        color: ${(props) => props.theme.secondary_400};
      }
    }
  }

  .select-footer {
    padding-left: 24px;
  }
`;
// Selector

const InputComponent = React.forwardRef(
  (
    {
      disabled,
      onChange,
      onClick,
      onSelected,
      value,
      placeholder,
      trailingicon,
      leadingicon,
      type,
      required,
      id,
      searchable,
      options,
      renderOption,
      autoCompleteMinLength = 3,
      debounceTimeout = 0,
      maxlength,
      className,
      combinedClassName = `form-control sub2 ${className}`,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useRef(null);

    const useOutsideAlerter = (ref) => {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);
    };

    const handleChangeValue = (e) => {
      if (maxlength && e.target.value.length > +maxlength) {
        return;
      }

      if (!e.target) {
        return;
      }

      setIsOpen(e.target.value.length >= autoCompleteMinLength);

      if (onChange) {
        onChange(e);
      }
    };

    const handleOptionClick = (value) => () => {
      if (onSelected) {
        onSelected(value);
      }
      setIsOpen(false);
    };

    const optionRender = (data) => {
      if (!data) {
        return null;
      }
      if (renderOption) {
        return renderOption(data);
      }
      return <SingleLineText data={data} />;
    };

    const handleOnClick = (e) => {
      if (onClick) {
        onClick(e);
      }

      if (!isOpen) {
        setIsOpen(true);
      }
    };

    useOutsideAlerter(wrapperRef);

    return (
      <React.Fragment>
        {debounceTimeout > 0 ? (
          <DebounceInput
            id={id}
            required={required}
            type={type}
            disabled={disabled}
            autoComplete={searchable ? 'off' : 'on'}
            onChange={handleChangeValue}
            onClick={handleOnClick}
            value={fixControlledValue(value)}
            placeholder={placeholder}
            className={combinedClassName}
            debounceTimeout={debounceTimeout}
            element={Input}
            ref={ref}
          />
        ) : (
          <Input
            id={id}
            required={required}
            type={type}
            disabled={disabled}
            autoComplete={searchable ? 'off' : 'on'}
            onChange={handleChangeValue}
            onClick={handleOnClick}
            value={fixControlledValue(value)}
            placeholder={placeholder}
            className={combinedClassName}
            ref={ref}
          />
        )}

        <i className='material-icons erroricon'>error</i>
        {trailingicon && <i className='material-icons trailingicon'>{trailingicon}</i>}
        {leadingicon && <i className='material-icons leadingicon'>{leadingicon}</i>}
        {searchable && isOpen && options && options.length > 0 && (
          <DropDownWrapper>
            <div ref={wrapperRef} className='select-body'>
              {options &&
                options.map((option) => (
                  <div className='select-option sub1' key={option.key} id={option.id} onClick={handleOptionClick(option.value)}>
                    {optionRender(option)}
                    {option.value === value && <i className='material-icons checked'>check_circle</i>}
                  </div>
                ))}
            </div>
          </DropDownWrapper>
        )}
      </React.Fragment>
    );
  }
);

export default InputComponent;
