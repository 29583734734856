import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, Section, ModalStyle } from '../../ui';

const Prompt = (props) => {
  const { when, onOk, onCancel, message, title, okText, cancelText } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOKClicked = useCallback(async () => {
    let canRoute;
    if (onOk) {
      canRoute = await Promise.resolve(onOk());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(canRoute);
  }, [currentPath, history, onOk]);

  const handleCancelClicked = useCallback(async () => {
    let canRoute;
    if (onCancel) {
      canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(canRoute);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      title={title}
      visible={showPrompt}
      onOk={handleOKClicked}
      okText={okText}
      onCancel={handleCancelClicked}
      cancelText={cancelText}
      closable={true}
      modalBoxStyle={ModalStyle.Minimum}>
      <Section>{message}</Section>
    </Modal>
  ) : null;
};

export default Prompt;
