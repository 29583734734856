import React from 'react';
import styled from 'styled-components';
import TableContext from './TableContext';
import { get } from 'lodash';

const Wrapper = styled.tr.attrs({ className: 'sub2' })`
  color: ${props => props.theme.gray_700};
  padding: 16px 0px;
  min-height: 74px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};

  &:hover {
    background: rgba(27, 64, 109, 0.04);
  }
`;

const DefaultCell = styled.div`
  color: ${props => props.theme.gray_700};
  text-align: ${props => props.align};
  padding: 16px 8px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: ${props => {
    switch (props.align) {
      case 'right': return 'flex-end';
      case 'center': return 'center';
      case 'left': return 'flex-start';
      default: return 'flex-start';
    }
  }};
`;

const fetchData = (data, key) => {
  if (typeof data === undefined) {
    return;
  }
  const index = key.indexOf('.');
  if (index > -1) {
    return fetchData(data[key.substring(0, index)], key.substr(index + 1));
  }
  return get(data, key);
}

const TableRow = ({
  data,
  columns,
  onClick
}) => {
  return (
    <Wrapper onClick={onClick}>
        {
          columns.map((column, idx) => (
            <td key={idx}>
              <DefaultCell {...column}>{column.render ? column.render(fetchData(data, column.dataIndex), data) : get(data, column.dataIndex)}</DefaultCell>
            </td>
          ))
        }
      <TableContext.Consumer>
        {({ actionButton }) => {
          if (actionButton) return <td><i className="material-icons" onClick={actionButton(data)}>more_horiz</i></td>
        }}
      </TableContext.Consumer>
    </Wrapper>
  )
}

export default TableRow;