import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: grid;
  position: relative;
  grid-column: 1 / span 12;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    background: ${(props) => props.theme.border_line};
    left: 8px;
    bottom: -1px;
  }

  @media only screen and (orientation: portrait) {
    display: block;
    padding-bottom: 8px;
  }
`;
const Title = styled.div.attrs({ className: 'headline6' })`
  color: ${(props) => props.theme.primary};
  grid-column: 1 / span 2;
  padding-left: 16px;
  padding-top: 16px;
`;

const FormDiv = styled.div`
  /* padding-top: ${(props) => (props.title ? '16px' : '0px')}; */
  padding-top: 16px;
  padding-left: ${(props) => (props.title ? '0' : '16px')};
  padding-right: 16px;
  grid-column: ${(props) => (props.title ? 3 : 1)} / -1;

  > * {
    margin-bottom: 16px;
  }

  @media only screen and (orientation: portrait) {
    padding-left: 16px;
  }
`;

const section = (props) => {
  const { visible = true } = props;
  if (!visible) {
    return null;
  }

  return (
    <Section {...props}>
      {props.title && <Title {...props}>{props.title}</Title>}
      <FormDiv {...props}>
        <div title=''>{props.children}</div>
      </FormDiv>
    </Section>
  );
};

export default section;
