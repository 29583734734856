// Actions
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

// Reducer
export default function reducer(state = { items: [] }, action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        items: [...state.items, action.data],
      };
    default:
      return state;
  }
}

// Action Creators
export const addNotification = (data) => {
  return {
    type: ADD_NOTIFICATION,
    data,
  };
};
