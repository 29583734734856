import React from 'react';
import styled from 'styled-components';
import defaultImg from '../../../../../assets/images/hg_mark-one_color_tonal_on_dark.svg';

const Wrapper = styled.div`
  user-select: none;
  cursor: default;
  grid-template-columns: auto 1fr;
  padding-right: ${(props) => (props.isInLine ? 48 : 0)}px;

  ${(props) => {
    if (props.isInLine) {
      return {
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      };
    }
  }}
`;

const Img = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  cursor: default;
`;

export default ({ data, isInLine }) => (
  <Wrapper isInLine={isInLine}>
    <Img src={data.img || defaultImg} />
    {data.text}
  </Wrapper>
);
