import axios from '../axios';

// Actions
export const LOAD_PAYLOAD_TYPE_LIST_SUCCESS = 'LOAD_PAYLOAD_TYPE_LIST_SUCCESS';
export const LOAD_PAYLOAD_TYPE_LIST_FAILED = 'LOAD_PAYLOAD_TYPE_LIST_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_PAYLOAD_TYPE_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_PAYLOAD_TYPE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
export const loadPayloadTypeListSuccess = (data) => {
  return {
    type: LOAD_PAYLOAD_TYPE_LIST_SUCCESS,
    data,
  };
};
export const loadPayloadTypeListFailed = (error) => {
  return {
    type: LOAD_PAYLOAD_TYPE_LIST_FAILED,
    error,
  };
};

export const loadPayloadTypeList = () => {
  return (dispatch) => {
    axios
      .get('/payloadtypes', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadPayloadTypeListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPayloadTypeListFailed(error));
      });
  };
};
