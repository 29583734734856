import axios from '../axios';

// Actions
export const LOAD_OBSTACLE_LIST_SUCCESS = 'LOAD_OBSTACLE_LIST_SUCCESS';
export const LOAD_OBSTACLE_LIST_FAILED = 'LOAD_OBSTACLE_LIST_FAILED';

export const CLEAR_OBSTACLE_LIST = 'CLEAR_OBSTACLE_LIST';

const initialState = {
  all: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_OBSTACLE_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_OBSTACLE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_OBSTACLE_LIST:
      return {
        ...state,
        all: [],
      };
    default:
      return state;
  }
}

export const loadObstacleListSuccess = (data) => {
  return {
    type: LOAD_OBSTACLE_LIST_SUCCESS,
    data,
  };
};

export const loadObstacleListFailed = (error) => {
  return {
    type: LOAD_OBSTACLE_LIST_FAILED,
    error,
  };
};

export const loadSiteObstacleList = (clientId, siteId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites/${siteId}/obstacles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadObstacleListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadObstacleListFailed(error));
      });
  };
};

export const loadTaskObstacleList = (taskId) => {
  return (dispatch) => {
    axios
      .get(`/tasks/${taskId}/obstacles`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadObstacleListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadObstacleListFailed(error));
      });
  };
};

export const clearObstacleList = () => {
  return {
    type: CLEAR_OBSTACLE_LIST,
  };
};
