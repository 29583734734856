import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_ORGANIZATION_SUCCESS = 'LOAD_ORGANIZATION_SUCCESS';
export const LOAD_ORGANIZATION_FAILED = 'LOAD_ORGANIZATION_FAILED';

export const UPDATE_ORGANIZATION_PROPERTY = 'UPDATE_ORGANIZATION_PROPERTY';
export const CLOSE_ORGANIZATION_FORM = 'CLOSE_ORGANIZATION_FORM';

export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS';
export const SAVE_ORGANIZATION_FAILED = 'SAVE_ORGANIZATION_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        info: {
          ...action.data,
        },
      };
    case LOAD_ORGANIZATION_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_ORGANIZATION_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case EDIT_ORGANIZATION:
      return {
        ...state,
        form: {
          ...state.info,
        },
      };
    case SAVE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        info: {
          ...action.data,
        },
      };
    case SAVE_ORGANIZATION_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const loadOrganizationSuccess = (data) => {
  return {
    type: LOAD_ORGANIZATION_SUCCESS,
    data,
  };
};

export const loadOrganizationFailed = (error) => {
  notification['error']({
    message: 'Error!',
    description: 'Cannot load organization information. Please try logging in again.',
  });
  return {
    type: LOAD_ORGANIZATION_FAILED,
    error,
  };
};

// Action Creators
export const loadOrganization = (id) => {
  return (dispatch) => {
    axios
      .get(`/organizations/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadOrganizationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadOrganizationFailed(error));
      });
  };
};

export const saveOrganizationSuccess = (data) => {
  notification['success']({
    message: i18next.t(`organization:control.notification.update_success_message.message`),
    description: i18next.t(`organization:control.notification.update_success_message.description`),
  });
  return {
    type: SAVE_ORGANIZATION_SUCCESS,
    data,
  };
};

export const saveOrganizationFailed = (error) => {
  notification['error']({
    message: i18next.t(`organization:control.notification.update_failed_message.message`),
    description: i18next.t(`organization:control.notification.update_failed_message.description`),
  });
  return {
    type: SAVE_ORGANIZATION_FAILED,
    error,
  };
};

export const updateOrganizationProperty = (data) => {
  return {
    type: UPDATE_ORGANIZATION_PROPERTY,
    data,
  };
};

export const editOrganization = () => {
  return {
    type: EDIT_ORGANIZATION,
  };
};

export const closeOrganizationForm = () => {
  return {
    type: CLOSE_ORGANIZATION_FORM,
  };
};

export const saveOrganization = (id, data) => {
  return (dispatch) => {
    axios
      .patch(`/organizations/${id}`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveOrganizationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveOrganizationFailed(error));
      });
  };
};

export const saveOrganizationPure = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `/organizations/${id}`,
        { ...data },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      return dispatch({
        type: SAVE_ORGANIZATION_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      return dispatch({
        type: SAVE_ORGANIZATION_FAILED,
        error,
      });
    }
  };
};
