import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { updateOrganizationProperty, goToNextStep } from '../../redux/registration';
import Icon from '../../assets/images/organization-form-icon.svg';
import { Input, Field, Button, Select } from '../ui';
import PhoneInput from '../ui/form/PhoneInput';
import { isUndefined } from 'lodash';

const Content = styled.div`
  border: 1px solid ${(props) => props.theme.border_line};
  border-radius: 4px;
  background-color: ${(props) => props.theme.light};
  padding: 36px 16px;
  width: 773px;
  margin: auto;
`;
const Header = styled.div`
  display: flex;
  height: 130px;
  img {
    height: 60px;
    margin-right: 24px;
  }
  h4 {
    color: ${(props) => props.theme.primary};
  }
  p {
    color: ${(props) => props.theme.gray_700};
  }
`;
const Body = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 16px;
`;

const BodyField = styled.div`
  grid-column: 2 / span 4;
`;

const SubmitButton = styled(Button).attrs({ colorTheme: 'primary' })`
  grid-column: 2 / span 4;
`;

class OrganizationForm extends Component {
  state = {
    errorField: {},
  };

  onChangeHandler = (field) => (e, data) => {
    const { errorField } = this.state;
    if (errorField[field]) {
      this.setState({ errorField: { ...errorField, [field]: undefined } });
    }
    if (field === 'phones') {
      // INFO: phone input produce onChange(value, country, e, formattedValue)
      const country = data;
      const value = e;
      this.props.updateProperty({
        [field]: {
          rawNumber: value,
          dialCode: country.dialCode,
        },
      });
    } else if (field === 'isIndividualUser') {
      this.props.updateProperty({ [field]: e.target.value === 'PERSONAL' });
      this.setState({ errorField: {} });
    } else {
      this.props.updateProperty({ [field]: e.target.value });
    }
  };

  onSubmit = () => {
    const { organizationInfo, goToNextStep } = this.props;
    const errorField = {};
    if (isUndefined(organizationInfo.isIndividualUser)) {
      errorField.isIndividualUser = 'Please select account type';
    }
    if (!organizationInfo.name || organizationInfo.name.length <= 0) {
      errorField.name = isUndefined(organizationInfo?.isIndividualUser)
        ? 'Please enter organization name / name'
        : organizationInfo?.isIndividualUser
        ? 'Please enter name'
        : 'Please enter organization name';
    }
    if (!organizationInfo.phones) {
      if (
        (organizationInfo.phones?.rawNumber?.length <= organizationInfo.phones?.dialCode?.length ||
          organizationInfo.phones?.rawNumber === 'undefined') &&
        !organizationInfo.phones?.dialCode
      ) {
        errorField.phones = 'Please select International Dialing Code and enter phone number';
      } else if (!organizationInfo.phones?.dialCode) {
        errorField.phones = 'Please select International Dialing Code';
      } else if (
        organizationInfo.phones?.rawNumber?.length <= organizationInfo.phones?.dialCode?.length ||
        organizationInfo.phones?.rawNumber === 'undefined'
      ) {
        errorField.phones = isUndefined(organizationInfo?.isIndividualUser)
          ? 'Please enter organization phone number / phone name'
          : organizationInfo?.isIndividualUser
          ? 'Please enter phone number'
          : 'Please enter organization phone number';
      }
    }
    if (!organizationInfo.phones || organizationInfo.phones?.rawNumber?.length <= organizationInfo.phones?.dialCode?.length)
      errorField.phones = isUndefined(organizationInfo?.isIndividualUser)
        ? 'Please enter organization phone number / phone number'
        : organizationInfo?.isIndividualUser
        ? 'Please enter phone number'
        : 'Please enter organization phone number';
    if (!organizationInfo.address || organizationInfo.address.length <= 0) {
      errorField.address = isUndefined(organizationInfo?.isIndividualUser)
        ? 'Please enter organization address / address'
        : organizationInfo?.isIndividualUser
        ? 'Please enter address'
        : 'Please enter organization address';
    }

    if (Object.keys(errorField).length > 0) {
      this.setState({ errorField });
    } else {
      goToNextStep();
    }
  };

  render() {
    const { onChangeHandler, onSubmit } = this;
    const { organizationInfo } = this.props;
    const { errorField } = this.state;

    const accountTypeOptions = [
      { value: 'PERSONAL', key: 'PERSONAL', text: 'Personal' },
      { value: 'ORGANIZATION', key: 'ORGANIZATION', text: 'Organization' },
    ];
    const accountValue = isUndefined(organizationInfo?.isIndividualUser)
      ? null
      : organizationInfo?.isIndividualUser
      ? 'PERSONAL'
      : 'ORGANIZATION';

    const namePlaceholder = isUndefined(organizationInfo?.isIndividualUser)
      ? 'Enter organization name / name'
      : organizationInfo?.isIndividualUser
      ? 'Enter name'
      : 'Enter organization name';

    const fieldTitleName =
      accountValue === 'ORGANIZATION' ? 'Organization Name' : accountValue === 'PERSONAL' ? 'Name' : 'Organization Name / Name';

    const addressPlaceholder = isUndefined(organizationInfo?.isIndividualUser)
      ? 'Enter organization address / address'
      : organizationInfo?.isIndividualUser
      ? 'Enter address'
      : 'Enter organization address';

    return (
      <Content>
        <Header>
          <img src={Icon} alt='HiveGrid Logo' />
          <div>
            <h4>Basic Information</h4>
            <p className='sub1'>Please tell us your basic account information by filling the information below.</p>
          </div>
        </Header>
        <hr />
        <Body>
          <BodyField>
            <Field title='Account type' isRequired={true} errorMessage={errorField.isIndividualUser}>
              <Select
                id='input-reg-org-account-type'
                title={'Select account type'}
                options={accountTypeOptions}
                value={accountValue}
                onChange={onChangeHandler('isIndividualUser')}
              />
            </Field>
            <Field title={fieldTitleName} isRequired={true} errorMessage={errorField.name}>
              <Input
                placeholder={namePlaceholder}
                value={organizationInfo?.name}
                onChange={onChangeHandler('name')}
                id='input-reg-org-name'
              />
            </Field>
            <Field title='Phone' isRequired={true} errorMessage={errorField.phones}>
              <PhoneInput
                disableColor={errorField.phones}
                buttonClass={'xButtonClass'}
                containerClass={'xContainerClass'}
                inputClass={'xInputClass'}
                dropdownClass={'xDropdownClass'}
                searchClass={'xSearchClass'}
                country={'us'}
                value={organizationInfo?.phones?.rawNumber}
                onChange={onChangeHandler('phones')}
                id='input-reg-org-phone'
              />
            </Field>
            <Field title='Address' isRequired={true} errorMessage={errorField.address}>
              <Input
                placeholder={addressPlaceholder}
                value={organizationInfo?.address}
                onChange={onChangeHandler('address')}
                id='input-reg-org-address'
              />
            </Field>
          </BodyField>
          <SubmitButton onClick={onSubmit} style={{ width: '100%' }} id='button-reg-continue'>
            CONTINUE
          </SubmitButton>
        </Body>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizationInfo: state.registration.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProperty: (data) => dispatch(updateOrganizationProperty(data)),
    goToNextStep: () => dispatch(goToNextStep()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
