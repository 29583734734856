import React from 'react';
import styled from 'styled-components';
import TableContext from './TableContext';

const Wrapper = styled.tr.attrs({ className: 'sub1' })`
  color: ${props => props.theme.gray_700};
`;

const SortArrow = styled.i.attrs({ className: 'sub1' })`
  display: ${props => props.children ? 'inline-block' : 'none'};
  font-family: 'Material Icons';
  font-style: normal;
`;

const HeaderColumn = styled.div`
  padding: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${props => {
    switch (props.align) {
      case 'right': return 'flex-end';
      case 'center': return 'center';
      case 'left': return 'flex-start';
      default: return 'flex-start';
    }
  }};
  user-select: none;

  ${SortArrow} {
    ${props => {
      switch (props.align) {
        case 'right': return 'margin-right: 10px;';
        case 'center': return 'margin-left: 10px;';
        case 'left': return 'margin-left: 10px;';
        default: return 'margin-left: 10px;';
      }
    }};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  width: 28px;
`;

const TexetWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

const TableHeader = ({ columns }) => {
  return (
    <Wrapper>
      <TableContext.Consumer>
        {({ sortData, sorted, reversed, actionButton }) => (
          <React.Fragment>
            {columns.map((column, idx) => (
              <th key={idx} width={column.width}>
                <HeaderColumn {...column} onClick={() => sortData(column.headerTitle, column.sorter)}>
                  <HeaderWrapper>
                    {column.align === 'right' && column.sorter &&
                      <IconWrapper>
                        <SortArrow>
                          {sorted === column.headerTitle && 'arrow_upward'}
                          {reversed === column.headerTitle && 'arrow_downward'}
                        </SortArrow>
                      </IconWrapper>
                    }
                    <TexetWrapper>{column.headerRender ? column.headerRender(column.headerTitle) : column.headerTitle}</TexetWrapper>
                    {column.align !== 'right' && column.sorter &&
                      <IconWrapper>
                        <SortArrow>
                          {sorted === column.headerTitle && 'arrow_upward'}
                          {reversed === column.headerTitle && 'arrow_downward'}
                        </SortArrow>
                      </IconWrapper>
                    }
                  </HeaderWrapper>
                </HeaderColumn>
              </th>
            ))}
            {
              actionButton && <th></th>
            }
          </React.Fragment>
        )}
      </TableContext.Consumer>
    </Wrapper>
  )
}

export default TableHeader;