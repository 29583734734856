import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: fit-content;
`;

const SectionTitle = styled.div`
  padding: 16px 12px;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: ${(props) =>
    props.disabled
      ? props.theme.gray_500
      : props.isInvalid
      ? props.theme.error
      : props.isOpen
      ? props.theme.gray_900
      : props.theme.gray_700};
  > i {
    position: absolute;
    right: 12px;
  }

  ${Wrapper}:not(:first-child) & {
    border-top: 1px solid ${(props) => props.theme.border_line};
  }

  ${Wrapper}:last-child & {
    border-bottom-width: ${(props) => (props.isOpen ? 0 : 1)}px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => props.theme.border_line};
  }
`;

const SectionBody = styled.div`
  max-height: ${(props) => {
    if (!props.isOpen) {
      return 0;
    } else {
      return props.maxHeight;
    }
  }}px;
  overflow-y: scroll;
  padding: 0px 16px;
  transition: max-height 0.4s ease, padding 0.4s;
`;

class Section extends Component {
  state = {
    isOpen: false,
  };

  onClick = () => {
    if (this.props.disabled) return;
    this.props.onClick(this.props.keyIndex);
  };

  render() {
    const { onClick } = this;
    const { label, isOpen, maxHeight, children, disabled, isInvalid, colorIcon } = this.props;
    return (
      <Wrapper>
        <SectionTitle onClick={onClick} disabled={disabled} isOpen={isOpen} isInvalid={isInvalid}>
          {label}
          <i style={{ color: colorIcon }} className='material-icons'>
            {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </i>
        </SectionTitle>
        <SectionBody maxHeight={maxHeight} isOpen={isOpen}>
          {children}
        </SectionBody>
      </Wrapper>
    );
  }
}

export default Section;
