import React from 'react';
import styled from 'styled-components';
import defaultImg from '../../../../assets/images/hg_mark-one_color_tonal_on_dark.svg';

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.border_line};
  border-radius: 4px;
  padding: 16px;
  background-color: ${props => props.theme.light};
  min-width: 220px;
`

const ImgWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 4px;
  margin-bottom: 16px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

const GeneralInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 4px;
  }
`

const TitleInfo = styled.div.attrs({ className: 'sub1' })`
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow-x: visible;
    span {
      position: relative;
      z-index: 1;
      background-color: ${props => props.theme.light};
    }
  }
`

const SubTitleInfo = styled.div.attrs({ className: 'sub2' })`
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  i {
    vertical-align: middle;
  }

  &:hover {
    overflow-x: visible;
    span {
      position: relative;
      z-index: 1;
      background-color: ${props => props.theme.light};
    }
  }
`

const CaptionInfo = styled.div.attrs({ className: 'caption' })`
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  i {
    vertical-align: middle;
  }

  &:hover {
    overflow-x: visible;
    span {
      position: relative;
      z-index: 1;
      background-color: ${props => props.theme.light};
    }
  };
`

const HorizontalLine = styled.hr`
  color: ${props => props.theme.border_line};
`

const ExtraInfoWrapper = styled.div``

const ExtraInfoItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`
const ExtraInfoField = styled.div.attrs({ className: 'overline' })`
  color: ${props => props.theme.gray_700};
  grid-column: 1 / span 2;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    overflow-x: visible;
    span {
      position: relative;
      z-index: 1;
      background-color: ${props => props.theme.light};
    }
  }
`
const ExtraInfoValue = styled.div.attrs({ className: 'headline6' })`
  grid-column: 3 / span 1;
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.primary};
`

export default props => {
  const { title, subtitle, caption, extraInfoList } = props;
  return (
    <Wrapper>
      <ImgWrapper>
        <img src={defaultImg} alt="" />
      </ImgWrapper>
      <GeneralInfoWrapper>
        {title && <TitleInfo><span>{title}</span></TitleInfo>}
        {subtitle && subtitle.value &&
          <SubTitleInfo>
            {subtitle.icon &&
              <i className="material-icons">
                {subtitle.icon}
              </i>
            }
            <span>
              {subtitle.value}
            </span>
          </SubTitleInfo>
        }
        {caption && caption.value &&
          <CaptionInfo>
            {caption.icon &&
              <i className="material-icons">
                {caption.icon}
              </i>
            }
            <span>
              {caption.value}
            </span>
          </CaptionInfo>}
      </GeneralInfoWrapper>
      <HorizontalLine />
      <ExtraInfoWrapper>
        {
          extraInfoList.map(x => (
            <ExtraInfoItem>
              <ExtraInfoField><span>{x.field}</span></ExtraInfoField>
              <ExtraInfoValue>{x.value}</ExtraInfoValue>
            </ExtraInfoItem>
          ))
        }
      </ExtraInfoWrapper>
    </Wrapper>
  )
}