import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  user-select: none;
  cursor: default;
  padding-right: ${(props)=> props.isInLine ? 48 : 0}px;

  ${(props) => {
    if (props.isInLine) {
      return {
        'white-space': 'nowrap',
        'overflow': 'hidden',
        'text-overflow': 'ellipsis',
      };
    }
  }}
`;

export default ({ data, isInLine }) => <Wrapper isInLine={isInLine}>{data.text}</Wrapper>;
